import { DatePipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Helper } from 'app/common/helper';
import { Constant, FIELD_COMPONENT, MODULE_NAME, TypeLanguage, TypeMediaFileEnum } from 'app/config/constants';
import { DialogConfirmComponent } from 'app/dialog/dialog-confirm/dialog-confirm.component';
import { DialogMessageComponent } from 'app/dialog/dialog-message/dialog-message.component';
import { DialogTranslateTicketComponent } from 'app/dialog/dialog-translate-ticket/dialog-translate-ticket.component';
import { DialogTrimingComponent } from 'app/dialog/dialog-triming/dialog-triming.component';
import { Ticket } from 'app/model/entity/ticket';
import { ApplicationDTO } from 'app/model/entity/ticket-editor/dto/application-DTO';
import { SpotDetails } from 'app/model/entity/ticket-editor/spot-details';
import { CommonService } from 'app/service/common.service';
import { DataService } from 'app/service/data.service';
import { DialogService } from 'app/service/dialog.service';
import { MediaService } from 'app/service/media.service';
import { MenuActionService } from 'app/service/menu-action.service';
import { TicketEditorService } from 'app/service/ticket-editor.service';
import { ApplicationService } from 'app/service/ticket-editor/application.service';
import * as fileSaver from 'file-saver';
import _ from 'lodash';
import { Subscription } from 'rxjs';
import { Spot } from './../../../model/entity/ticket-editor/spot';
import { TicketInformation } from './../../../model/entity/ticket-editor/ticket-information';
@Component({
  selector: 'ticket-editor-tab-spot',
  templateUrl: './ticket-editor-tab-spot.component.html',
  styleUrls: ['./ticket-editor-tab-spot.component.scss']
})
export class TicketEditorTabSpotComponent implements OnInit, OnDestroy {
  @ViewChild('spotId') spotIdElementRef: ElementRef;
  @ViewChild('spotName') spotNameElementRef: ElementRef;
  @Input() tabSelected: number; // tab selected
  @Input() informationAccount: any;
  MODULE_NAME = MODULE_NAME;
  FIELD_COMPONENT = FIELD_COMPONENT;
  subscriptions: Array<Subscription> = new Array<Subscription>(); //array subscription
  spotDetailSelected: SpotDetails; // spot Deatail Selected
  ticketInformationSelected: TicketInformation[]; // ticket infomation selected
  spotDetailSelectedCloneDepp: SpotDetails; // spot Deatail Selected clone deep
  ticketInformationSelectedCloneDeep: TicketInformation[]; // ticket infomation selected clone deep
  spotSelected: Spot; // spot Selected
  placeholderSearch: string; // Set text placeholder input search
  listSpot: Spot[];
  listSpotDetails: Array<SpotDetails>; // list Spot Details
  appSelected: string;
  listSpotCloneDeppFirst: Spot[];
  listSpotCloneDeppSecond: Spot[];
  public languageKey: string; //language key
  TypeLanguage = TypeLanguage;
  isEditSpot: boolean;
  listApp: ApplicationDTO[] = []; // list Application
  languages: any = new Array<any>();
  dataFilter = []; // List ticket after filter
  inputSearch = Constant.EMPTY; // Value input search
  labelAddImage: string; //label Add Image
  image: any; // image
  imageCircle: any; // image Circle
  base64Selected: any;
  /**
   * Image types allowed
   */
  private imageTypesAllowed = [TypeMediaFileEnum.JPG, TypeMediaFileEnum.PNG, TypeMediaFileEnum.JPEG];
  acceptFileTypes: string = '.jpg, .png, .jpeg';
  listPropValidate: string[] = ['SpotNameJa', 'SpotNameEn', 'AppName'];
  isDuplicate: boolean;
  private readonly IMAGE_NAME_TS = 'spot';
  private readonly IMAGE_NAME_TS_TRIMING = 'spot_circle';
  private readonly MODE_GETFILENAMEORIFINAL = 1;
  tickets: Array<Ticket> = new Array<Ticket>(); // list ticket

  @ViewChild('fileInput') fileInput: ElementRef;

  @Output() saveDataSuccess: EventEmitter<boolean> = new EventEmitter<boolean>(); // save data success
  constructor(
    private menuActionService: MenuActionService,
    private translateService: TranslateService,
    private dataService: DataService,
    private dialogService: DialogService,
    public datepipe: DatePipe,
    private commonService: CommonService,
    private mediaService: MediaService,
    private ticketService: TicketEditorService,
    private applicationService: ApplicationService,
    private sanitizer: DomSanitizer
  ) {
    this.placeholderSearch = this.translateService.instant('ticket-editor.spot-tab.search');
    this.labelAddImage = this.translateService.instant('ticket-editor.spot-tab.add-image');
    // Action add
    this.subscriptions.push(
      this.menuActionService.actionAdd.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TicketEditorComponent] && this.tabSelected == Constant.SPOT_ENUM) {
          this.addNewSPot();
        }
      }),
      // Action edit
      this.menuActionService.actionEdit.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TicketEditorComponent] && this.tabSelected == Constant.SPOT_ENUM) {
          this.editSpot();
        }
      }),
      // Action Delete
      this.menuActionService.actionDelete.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TicketEditorComponent] && this.tabSelected == Constant.SPOT_ENUM) {
          this.deleteSpot();
        }
      }),

      // Action duplicate
      this.menuActionService.actionDuplicate.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TicketEditorComponent] && this.tabSelected == Constant.SPOT_ENUM) {
          this.duplicate();
        }
      }),
      this.menuActionService.actionTranslateTicket.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TicketEditorComponent] && this.tabSelected == Constant.SPOT_ENUM) {
          this.translateTicket();
        }
      })
    );

    // Get language
    this.translateService.onLangChange.subscribe(() => {
      // Translate title
      this.placeholderSearch = this.translateService.instant('ticket-editor.spot-tab.search');
      this.labelAddImage = this.translateService.instant('ticket-editor.spot-tab.add-image');
    });

    this.subscriptions.push(
      this.translateService.onLangChange.subscribe(() => {
        // multi language
        this.languageKey = this.commonService.getCommonObject().setting?.language;
      })
    );
  }

  async ngOnInit(): Promise<void> {
    this.languageKey = this.commonService.getCommonObject().setting?.language;
    this.listSpot = [];
    await this.getAllApplication();
    await this.getAllTicket();
    this.dataFilter = this.listSpot;
    this.dataService.sendData([Constant.IS_CALL_API_TAB_SPOT, false]);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  /**
   * selectSpot
   * @param spot
   * @param event
   * @param isNotReload
   */
  selectSpot(spot: Spot, event, isNotReload?: boolean): void {
    this.image = undefined;
    this.imageCircle = undefined;
    this.base64Selected = undefined;
    if ((this.spotSelected == spot && !isNotReload) || event?.target?.id === 'checkBoxSpot' || this.spotSelected?.isEdit) {
      return;
    }
    if (spot.spotDetails.spotQrCode && !spot.spotDetails.qrBase64) {
      this.ticketService.generateQRCode(spot.spotDetails.spotQrCode).subscribe(data => {
        this.base64Selected = data;
        spot.spotDetails.qrBase64 = this.sanitizer.bypassSecurityTrustHtml(this.base64ToSvg(this.base64Selected));
        this.spotSelected = spot;
        this.spotDetailSelected = spot.spotDetails;
        this.spotDetailSelectedCloneDepp = _.cloneDeep(this.spotDetailSelected);
      });
    } else {
      this.spotSelected = spot;
      this.spotDetailSelected = spot.spotDetails;
      this.spotDetailSelectedCloneDepp = _.cloneDeep(this.spotDetailSelected);
    }
    this.ticketInformationSelected = spot.ticketInformation;
    for (let i = 0; i < this.ticketInformationSelected.length; i++) {
      this.ticketInformationSelected[i].id = i;
    }
    this.ticketInformationSelectedCloneDeep = _.cloneDeep(this.ticketInformationSelected);
  }

  private base64ToSvg(base64: string | null): string | null {
    return base64;
  }

  /**
   * add new spot
   */
  addNewSPot(): void {
    this.isEditSpot = true;
    let spotNew = new Spot(null, null, {
      ja: '',
      en: ''
    });
    let spotDetailsNew = new SpotDetails();
    spotDetailsNew.spotName = {
      ja: '',
      en: ''
    };

    let ticketInfoNew = new TicketInformation();
    ticketInfoNew.ticketName = {
      ja: '',
      en: ''
    };
    spotNew.spotDetails = spotDetailsNew;
    spotNew.ticketInformation = [];
    this.listSpot.push(spotNew);
    this.selectSpot(spotNew, null);
    this.dataService.sendData([Constant.IS_EDITING_SPOT, this.isEditSpot]);
  }

  /**
   * save spot
   */
  async saveSpot(): Promise<void> {
    if (this.validateBeforeSave()) {
      return;
    }
    if (!this.isDuplicate) {
      if (this.spotSelected.spotId) {
        if (this.image && this.imageCircle) {
          const image1$ = await Helper.convertFileToBase64(this.image as File);
          const imageCircle$ = await Helper.convertFileToBase64(this.imageCircle as File);
          let payload1 = {
            image: image1$,
            uploadImageType: 'spots'
          };
          let payload2 = {
            image: imageCircle$,
            uploadImageType: 'spots'
          };

          const uploadPromises = [
            this.ticketService.postImage(this.informationAccount, payload1).toPromise(),
            this.ticketService.postImage(this.informationAccount, payload2).toPromise()
          ];
          Promise.all(uploadPromises)
            .then(responses => {
              this.spotDetailSelected.spotImage = Helper.getFileNameFromUrl(responses[0]?.filePath);
              this.spotDetailSelected.imagePath = responses[0]?.filePath;
              this.spotDetailSelected.spotImageCircle = Helper.getFileNameFromUrl(responses[1]?.filePath);
              this.spotDetailSelected.imageCirclePath = responses[1]?.filePath;
              this.updateSpot();
            })
            .catch(error => {
              Helper.getErrorTicket(error, this.translateService, this.dialogService);
              this.spotDetailSelected = _.cloneDeep(this.spotDetailSelectedCloneDepp);
              this.ticketInformationSelected = _.cloneDeep(this.ticketInformationSelectedCloneDeep);
              return;
            });
        } else {
          this.updateSpot();
        }
      } else {
        if (this.image && this.imageCircle) {
          const image1$ = await Helper.convertFileToBase64(this.image as File);
          const imageCircle$ = await Helper.convertFileToBase64(this.imageCircle as File);
          let payload1 = {
            image: image1$,
            uploadImageType: 'spots'
          };
          let payload2 = {
            image: imageCircle$,
            uploadImageType: 'spots'
          };

          const uploadPromises = [
            this.ticketService.postImage(this.informationAccount, payload1).toPromise(),
            this.ticketService.postImage(this.informationAccount, payload2).toPromise()
          ];

          Promise.all(uploadPromises)
            .then(responses => {
              this.spotDetailSelected.spotImage = Helper.getFileNameFromUrl(responses[0]?.filePath);
              this.spotDetailSelected.imagePath = responses[0]?.filePath;
              this.spotDetailSelected.spotImageCircle = Helper.getFileNameFromUrl(responses[1]?.filePath);
              this.spotDetailSelected.imageCirclePath = responses[1]?.filePath;
              this.createSpot();
              this.dataService.sendData([Constant.IS_EDITING_SPOT, this.isEditSpot]);
            })
            .catch(error => {
              Helper.getErrorTicket(error, this.translateService, this.dialogService);
              this.spotDetailSelected = _.cloneDeep(this.spotDetailSelectedCloneDepp);
              this.ticketInformationSelected = _.cloneDeep(this.ticketInformationSelectedCloneDeep);
              return;
            });
        } else {
          this.createSpot();
        }
      }
    } else {
      if (!this.spotDetailSelected.spotImage) {
        this.createSpot();
      } else {
        let image1$;
        let imageCircle$;
        const res1 = await new Promise<any>((resolve, reject) => {
          this.mediaService.getFileFromUrl(this.spotSelected.spotDetails.spotImageURL).subscribe(resolve, reject);
        });

        const res2 = await new Promise<any>((resolve, reject) => {
          this.mediaService.getFileFromUrl(this.spotSelected.spotDetails.spotImageCircleURL).subscribe(resolve, reject);
        });

        const file1 = new File([res1.body], 'image.png', {
          type: 'image/jpeg'
        });
        const file2 = new File([res2.body], 'image.png', {
          type: 'image/jpeg'
        });

        image1$ = await Helper.convertFileToBase64(file1);
        imageCircle$ = await Helper.convertFileToBase64(file2);

        let payload1 = {
          image: image1$,
          uploadImageType: 'spots'
        };
        let payload2 = {
          image: imageCircle$,
          uploadImageType: 'spots'
        };

        const uploadPromises = [
          this.ticketService.postImage(this.informationAccount, payload1).toPromise(),
          this.ticketService.postImage(this.informationAccount, payload2).toPromise()
        ];

        Promise.all(uploadPromises)
          .then(responses => {
            this.spotDetailSelected.spotImage = Helper.getFileNameFromUrl(responses[0]?.filePath);
            this.spotDetailSelected.imagePath = responses[0]?.filePath;
            this.spotDetailSelected.spotImageCircle = Helper.getFileNameFromUrl(responses[1]?.filePath);
            this.spotDetailSelected.imageCirclePath = responses[1]?.filePath;
            this.createSpot();
          })
          .catch(error => {
            Helper.getErrorTicket(error, this.translateService, this.dialogService);
            this.spotDetailSelected = _.cloneDeep(this.spotDetailSelectedCloneDepp);
            this.ticketInformationSelected = _.cloneDeep(this.ticketInformationSelectedCloneDeep);
            return;
          });
      }
    }
  }

  /**
   * updateSpot
   * @returns
   */
  updateSpot(): void {
    const index = this.listSpot.findIndex(spot => spot.spotId == this.spotSelected.spotId);
    if (index == -1) {
      return;
    }
    const ticketList = this.ticketInformationSelected.map(ticket => ({
      ticketId: +ticket.ticketId,
      note: {
        ja: ticket.note.ja,
        en: ticket.note.en
      }
    }));
    let payload = {
      spotId: this.spotSelected.spotId ?? null,
      appId: this.spotDetailSelected.appId ?? null,
      spotName: this.spotDetailSelected.spotName ?? null,
      ticketList: ticketList.length ? ticketList : null,
      imagePath: this.spotDetailSelected.imagePath ?? null,
      imageCirclePath: this.spotDetailSelected.imageCirclePath ?? null
    };
    this.ticketService.putSpot(this.informationAccount, payload).subscribe(
      spotId => {
        this.isEditSpot = false;
        this.dataService.sendData([Constant.IS_EDITING_SPOT, this.isEditSpot]);
        this.ticketService.getSpots(this.informationAccount, this.appSelected).subscribe(data => {
          this.listSpot = Helper.convertSpots(data);
          this.dataFilter = this.listSpot.filter(e => e.appId == this.appSelected);
          if (this.dataFilter.length == 0) {
            this.spotSelected = null;
            this.spotDetailSelected = null;
            this.ticketInformationSelected = null;
          } else {
            const index = this.listSpot.findIndex(spot => spot.spotId == spotId.spotId);
            this.selectSpot(this.dataFilter[index], null);
            this.spotDetailSelectedCloneDepp = _.cloneDeep(this.spotDetailSelected);
            this.ticketInformationSelectedCloneDeep = _.cloneDeep(this.ticketInformationSelected);
            this.image = undefined;
            this.imageCircle = undefined;
          }
        });
      },
      error => {
        Helper.getErrorTicket(error, this.translateService, this.dialogService);
        this.spotDetailSelected = _.cloneDeep(this.spotDetailSelectedCloneDepp);
        this.ticketInformationSelected = _.cloneDeep(this.ticketInformationSelectedCloneDeep);
      }
    );
  }

  /**
   * validateBeforeSave
   * @returns
   */
  validateBeforeSave(): boolean {
    this.listPropValidate.forEach(e => {
      this.validateSpot(e);
    });
    const properties = Object.keys(this.spotDetailSelected);
    for (let property of properties) {
      if (property.includes('isValidate') && this.spotDetailSelected[property]) {
        return true;
      }
    }
    return false;
  }

  /**
   * createSpot
   */
  createSpot(): void {
    let payload = {
      spotName: this.spotDetailSelected.spotName,
      appId: this.spotDetailSelected.appId,
      ticketList: this.ticketInformationSelected.map(ticket => ({
        ticketId: +ticket.ticketId,
        note: {
          ja: ticket.note.ja,
          en: ticket.note.en
        }
      })),
      imagePath: this.spotDetailSelected.imagePath,
      imageCirclePath: this.spotDetailSelected.imageCirclePath
    };
    this.ticketService.postSpot(this.informationAccount, payload).subscribe(
      spotId => {
        this.isEditSpot = false;
        this.dataService.sendData([Constant.IS_EDITING_SPOT, this.isEditSpot]);
        this.isDuplicate = false;
        this.ticketService.getSpots(this.informationAccount, this.appSelected).subscribe(data => {
          this.listSpot = Helper.convertSpots(data);
          this.dataFilter = this.listSpot.filter(e => e.appId == this.appSelected);
          if (this.dataFilter.length == 0) {
            this.spotSelected = null;
            this.spotDetailSelected = null;
            this.ticketInformationSelected = null;
          } else {
            const index = this.listSpot.findIndex(spot => spot.spotId === spotId.spotId);
            this.selectSpot(this.dataFilter[index], null);
            this.spotDetailSelectedCloneDepp = _.cloneDeep(this.spotDetailSelected);
            this.ticketInformationSelectedCloneDeep = _.cloneDeep(this.ticketInformationSelected);
          }
        });
      },
      error => {
        Helper.getErrorTicket(error, this.translateService, this.dialogService);
        this.spotDetailSelected = _.cloneDeep(this.spotDetailSelectedCloneDepp);
        this.ticketInformationSelected = _.cloneDeep(this.ticketInformationSelectedCloneDeep);
      }
    );
  }

  /**
   * cancel save spot
   */
  cancelSaveSpot(): void {
    this.isEditSpot = false;
    this.dataService.sendData([Constant.IS_EDITING_SPOT, this.isEditSpot]);

    if (!this.isDuplicate) {
      if (!this.spotSelected.spotId) {
        this.listSpot.pop();
        this.selectSpot(this.listSpot[Constant.FIRST_ELEMENT_INDEX], null);
      } else {
        const index = this.listSpot.findIndex(spot => spot.spotId == this.spotSelected.spotId);
        if (index == -1) {
          return;
        }
        this.spotDetailSelected = _.cloneDeep(this.spotDetailSelectedCloneDepp);
        this.ticketInformationSelected = _.cloneDeep(this.ticketInformationSelectedCloneDeep);
        this.listSpot[index].spotDetails = this.spotDetailSelectedCloneDepp;
        this.listSpot[index].ticketInformation = this.ticketInformationSelectedCloneDeep;
        this.selectSpot(this.spotSelected, null);
      }
    } else {
      this.listSpot.pop();
      this.selectSpot(this.listSpot[Constant.FIRST_ELEMENT_INDEX], null);
      this.isDuplicate = false;
    }
  }

  /**
   * validate spot when add new/ edit
   * @param prop
   */
  validateSpot(prop: string): void {
    switch (prop) {
      case 'SpotNameJa':
        this.spotDetailSelected['isValidateRequiredSpotNameJa'] = this.spotDetailSelected.spotName.ja.trim().length ? false : true;
        if (this.spotDetailSelected['isValidateRequiredSpotNameJa']) {
          break;
        }
        this.spotDetailSelected['isValidateLengthSpotNameJa'] =
          this.spotDetailSelected.spotName.ja.trim().length &&
          this.spotDetailSelected.spotName.ja.trim().length >= 1 &&
          this.spotDetailSelected.spotName.ja.trim().length <= 50
            ? false
            : true;
        break;

      case 'SpotNameEn':
        this.spotDetailSelected['isValidateRequiredSpotNameEn'] = this.spotDetailSelected.spotName.en.trim().length ? false : true;
        if (this.spotDetailSelected['isValidateRequiredSpotNameEn']) {
          break;
        }
        this.spotDetailSelected['isValidateLengthSpotNameEn'] =
          this.spotDetailSelected.spotName.en.trim().length &&
          this.spotDetailSelected.spotName.en.trim().length >= 1 &&
          this.spotDetailSelected.spotName.en.trim().length <= 50
            ? false
            : true;
        break;

      case 'AppName':
        this.spotDetailSelected['isValidateRequiredAppName'] = this.spotDetailSelected.appId ? false : true;
    }
  }

  /**
   * Get title item
   * @param msg
   * @param param
   * @param param2
   */
  getStringTranslate(msg: string, param: string, param2?: string): string {
    const dataString = param2
      ? Helper.formatString(this.translateService.instant(`ticket-editor.combination.${msg}`), `${param}`, `${param2}`)
      : Helper.formatString(this.translateService.instant(`ticket-editor.combination.${msg}`), `${param}`);
    return dataString;
  }

  /**
   * edit spot
   */
  editSpot(): void {
    if (!this.spotSelected) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('ticket-editor.choose-spot')
        }
      });
      return;
    } else if (this.isEditSpot) {
      return;
    }

    this.isEditSpot = true;
    this.dataService.sendData([Constant.IS_EDITING_SPOT, this.isEditSpot]);
  }

  /**
   * delete spot
   */
  deleteSpot(): void {
    const checkedSpot: string[] = this.listSpot.filter(spot => spot.isChecked).map(spot => spot.spotId);
    if (!checkedSpot?.length) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('ticket-editor.choose-spot')
        }
      });
      return;
    }
    if (this.spotSelected?.isEdit) {
      return;
    }
    this.dialogService.showDialog(
      DialogConfirmComponent,
      {
        data: {
          text: this.translateService.instant('ticket-editor.confirm-delete-checked-spot'),
          button1: this.translateService.instant('ticket-editor.yes'),
          button2: this.translateService.instant('ticket-editor.btn-no')
        }
      },
      result => {
        if (!result) {
          return;
        }
        let payloads = [];
        for (let spotId of checkedSpot) {
          let payload = {
            spotId: spotId
          };
          payloads.push(payload);
        }
        this.ticketService.deleteSpotsSequentially(this.informationAccount, payloads).subscribe(result => {
          this.ticketService.getSpots(this.informationAccount, this.appSelected).subscribe(data => {
            this.listSpot = Helper.convertSpots(data);
            this.dataFilter = this.listSpot.filter(e => e.appId == this.appSelected);
            if (this.dataFilter.length == 0) {
              this.spotSelected = null;
              this.spotDetailSelected = null;
              this.ticketInformationSelected = null;
            } else {
              this.selectSpot(this.dataFilter[0], null);
            }
          });
        });
      }
    );
  }

  /**
   * check or uncheck a application
   * @param id
   * @param e
   */
  changeChecked(id: any, e): void {
    e.stopPropagation();
    const index = this.listSpot.findIndex(element => element.spotId === id);
    this.listSpot[index].isChecked = !this.listSpot[index].isChecked;
    if (this.listSpot[index].spotId == this.spotSelected?.spotId) {
      this.spotSelected.isChecked = this.listSpot[index].isChecked;
    }
  }

  /**
   * changeShowNote
   * @param index
   */
  changeShowNote(index: number): void {
    this.ticketInformationSelected[index].isShowNote = !this.ticketInformationSelected[index].isShowNote;
    this.ticketInformationSelected[index].languageSelected = this.languageKey == 'jp' ? 'en' : 'ja';
  }

  /**
   * showSpotName
   */
  showSpotName(): void {
    this.spotDetailSelected.isShowSpotName = !this.spotDetailSelected.isShowSpotName;
  }

  /**
   * showSpotName
   */
  showTicketName(id: number): void {
    const index = this.ticketInformationSelected.findIndex(item => item.id == id);
    this.ticketInformationSelected[index].isShowTicketName = !this.ticketInformationSelected[index].isShowTicketName;
  }

  /**
   * getAppName
   * @param id
   * @returns
   */
  getAppName(id: string): string {
    const app = this.listApp.find(item => item.appId == id);
    return app ? app.name : '';
  }

  /**
   * getNameTicketId
   * @param id
   * @returns
   */
  getTicket(idTicket: number): Ticket {
    return this.tickets.find(item => item.ticketId == idTicket);
  }

  changeTicket(id: number) {
    const index = this.ticketInformationSelected.findIndex(item => item.id == id);
    const ticketSelected = this.tickets.find(item => item.ticketId == this.ticketInformationSelected[index].ticketId);
    this.ticketInformationSelected[index].ticketName = ticketSelected.ticketName;
  }

  /**
   * checkCharInString
   * @param str
   * @param dataSearch
   * @returns
   */
  checkCharInString(str: string, dataSearch: string): boolean {
    if (str.toLowerCase().includes(dataSearch.toLowerCase())) {
      return true;
    }
    return false;
  }

  /**
   * searchSpot
   * @param inputSearch
   */
  searchSpot(inputSearch: string): void {
    this.dataFilter = [...this.listSpot];
    if (inputSearch != '') {
      this.dataFilter = [
        ...this.listSpot.filter(
          spot =>
            this.checkCharInString(this.languageKey == 'jp' ? spot.spotName.ja : spot.spotName.en, inputSearch) &&
            spot.appId == this.appSelected
        )
      ];
      if (this.dataFilter.length == 0) {
        this.spotSelected = null;
        this.spotDetailSelected = null;
        this.ticketInformationSelected = null;
      } else {
        this.selectSpot(this.dataFilter[0], null);
      }
    } else {
      this.clearInputSearch();
    }
  }

  /**
   * clearInputSearch
   */
  clearInputSearch(): void {
    this.inputSearch = '';
    this.dataFilter = this.listSpot.filter(e => e.appId == this.appSelected);
    if (this.dataFilter.length == 0) {
      this.spotSelected = null;
      this.spotDetailSelected = null;
      this.ticketInformationSelected = null;
    } else {
      this.selectSpot(this.dataFilter[0], null);
    }
  }

  /**
   * add Detail
   * @param property
   */
  public addDetail(property: string): void {
    this.spotDetailSelected[`isDetail${property}`] = !this.spotDetailSelected[`isDetail${property}`];
  }

  /**
   * add Table Ticket Info
   */
  addTableTicketInfo(): void {
    let ticketInformation = new TicketInformation(
      null,
      null,
      {
        ja: '',
        en: ''
      },
      {
        ja: '',
        en: ''
      },
      this.languageKey == 'jp' ? 'ja' : 'en'
    );
    this.ticketInformationSelected.push(ticketInformation);
    for (let i = 0; i < this.ticketInformationSelected.length; i++) {
      this.ticketInformationSelected[i].id = i;
    }
  }

  /**
   * deleteTicketInfo
   * @param idTicket
   */
  deleteTicketInfo(idTicket: number): void {
    this.ticketInformationSelected = this.ticketInformationSelected.filter(ticket => ticket.id !== idTicket);
  }

  /**
   * change app Name
   */
  changeAppId(): void {
    this.dataService.sendData([Constant.IS_CALL_API_TAB_SPOT, this.appSelected == '-1' ? false : true]);
    if (!this.appSelected) {
      return;
    }
    if (this.appSelected == '-1') {
      this.listSpot = undefined;
      this.dataFilter = undefined;
      this.spotSelected = undefined;
      this.spotDetailSelected = undefined;
      this.ticketInformationSelected = undefined;
    }
    this.ticketService.getSpots(this.informationAccount, this.appSelected).subscribe(data => {
      this.listSpot = Helper.convertSpots(data);
      this.dataFilter = this.listSpot.filter(e => e.appId == this.appSelected);
      if (this.dataFilter.length == 0) {
        this.spotSelected = null;
        this.spotDetailSelected = null;
        this.ticketInformationSelected = null;
      } else {
        this.selectSpot(this.dataFilter[0], null);
      }
    });
  }

  /**
   * addImage
   * @param event
   * @returns
   */
  addImage(event: any) {
    const fileInput = event.target;
    const selectedFile = fileInput.files[0];
    if (!selectedFile) {
      return;
    }
    if (
      !this.imageTypesAllowed.includes(
        selectedFile?.name
          .split('.')
          .pop()
          ?.toLowerCase()
      )
    ) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('ticket-editor.spot-tab.file-not-supported')
        }
      });
      this.fileInput.nativeElement.value = null;
      return;
    }

    let fileName = selectedFile?.name.substring(0, selectedFile?.name?.lastIndexOf('.'));
    if (fileName.match(Constant.FORMAT)) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          textHasLineBreaks: this.translateService.instant('ticket-editor.spot-tab.does-not-contain-special-characters')
        }
      });
      this.fileInput.nativeElement.value = null;
      return;
    }

    this.dialogService.showDialog(
      DialogTrimingComponent,
      {
        data: {
          dataEvent: selectedFile
        }
      },
      res => {
        if (!res) {
          this.fileInput.nativeElement.value = null;
          return;
        }
        this.image = res[0];
        this.imageCircle = res[1];
        this.spotDetailSelected.spotImage = `${res[0]?.name}`;
        this.spotDetailSelected.spotImageCircle = `${res[1]?.name}`;
      }
    );
  }

  /**
   *editImage
   * @returns
   */
  editImage() {
    let data;

    let isEdit = this.spotDetailSelected.id ? true : false;

    if (!isEdit) {
      data = {
        dataEvent: this.image
      };
    }

    if (isEdit) {
      if (this.image) {
        data = {
          dataEvent: this.image
        };
      } else {
        data = {
          data: null,
          isEdit: true,
          dataURL: {
            originalFileURL: this.spotDetailSelected.spotImageURL,
            editedFileURL: this.spotDetailSelected.spotImageCircleURL
          }
        };
      }
    }

    this.dialogService.showDialog(
      DialogTrimingComponent,
      {
        data
      },
      res => {
        if (!res) {
          this.fileInput.nativeElement.value = null;
          return;
        }
        this.image = res[0];
        this.imageCircle = res[1];
        this.spotDetailSelected.spotImage = `${res[0]?.name}`;
        this.spotDetailSelected.spotImageCircle = `${res[1]?.name}`;
      }
    );
  }

  duplicate(): void {
    if (!this.spotSelected) {
      return;
    }
    this.isDuplicate = true;
    let spot = _.cloneDeep(this.spotSelected);
    this.listSpot.push(spot);
    this.dataFilter.push(spot);
    this.selectSpot(spot, null);
    this.isEditSpot = true;
    this.dataService.sendData([Constant.IS_EDITING_SPOT, this.isEditSpot]);
  }

  /**
   * getFileFromUrl
   * @param url
   * @returns
   */
  getFileFromUrl(url: string, fileName: string): Promise<File> {
    return new Promise((resolve, reject) => {
      fetch(url)
        .then(response => response.blob())
        .then(blob => {
          const file = new File([blob], fileName, { type: 'image/png' });
          resolve(file);
        })
        .catch(error => {
          console.error('Error fetching file:', error);
          reject(error);
        });
    });
  }

  /**
   * getFileNameFromUrl
   * @param url
   * @returns
   */
  convertFileNameFromUrl(url: string, mode?: number): string {
    const filenameWithExtension = url.substring(url.lastIndexOf('/') + 1, url.indexOf('?'));
    const filename = filenameWithExtension.split('/').pop();
    let mediaType = filename.slice(filename.lastIndexOf('.') + 1, filename.length).toLowerCase();
    let mediaName;
    if (mode && mode == this.MODE_GETFILENAMEORIFINAL) {
      mediaName = `${filename.split('_')[0]}_${this.IMAGE_NAME_TS}.${mediaType}`;
    } else {
      mediaName = `${filename.split('_')[0]}_${this.IMAGE_NAME_TS_TRIMING}.${mediaType}`;
    }
    return mediaName;
  }

  /**
   * translateTicket
   */
  translateTicket(): void {
    this.dialogService.showDialog(DialogTranslateTicketComponent, {}, result => {
      this.languages = result;
    });
  }

  /**
   * get list application
   * @param appSelect
   */
  async getAllApplication(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.applicationService.findAllApplication().subscribe(
        res => {
          let listAppDB = Helper.convertResApplication(res);
          if (listAppDB && listAppDB.length) {
            this.listApp = listAppDB.filter(e => e.appId);
          }
          resolve();
        },
        error => {
          Helper.getErrorTicket(error, this.translateService, this.dialogService);
          reject();
        }
      );
    });
  }

  /**
   * getAllTicket
   * @returns
   */
  async getAllTicket(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.ticketService.getTickets(this.informationAccount).subscribe(
        data => {
          this.tickets = Helper.convertTicket(data);
          resolve();
        },
        error => {
          Helper.getErrorTicket(error, this.translateService, this.dialogService);
          reject();
        }
      );
    });
  }
  /**
   * Get name app display
   * @param value nameApp
   * @returns
   */
  changeDisplayApp(value: String): String {
    let temp = _.cloneDeep(value).toString();
    if (temp?.split('W')?.length > 10 && temp.length > 28) {
      value = value.substring(0, 28) + '...';
    } else if (value.length > 50) {
      value = value.substring(0, 50) + '...';
    }
    return value;
  }

  /**
   * Get name app display
   * @param value nameApp
   * @returns
   */
  changeDisplay(value): String {
    if (value && value.length > 10) {
      value = value.substring(0, 10) + '...';
    }
    return value;
  }

  downloadQr() {
    if (!this.spotDetailSelected) {
      return;
    }
    const fileNameResponse = `QR_${
      this.languageKey == 'jp' ? this.spotDetailSelected.spotName.ja : this.spotDetailSelected?.spotName.en
    }.svg`;
    const svgContent = this.base64Selected;
    const blob = new Blob([svgContent], { type: 'image/svg+xml' });
    const file = new File([blob], fileNameResponse, {
      type: 'image/svg+xml'
    });
    fileSaver.saveAs(file);
  }
}

export interface DataURL {
  originalFileURL: string;
  editedFileURL: string;
}
interface Language {
  ja: string; // Japanese language representation
  en: string; // English language representation
}
