import { CdkDrag, CdkDragDrop, CdkDropList, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Helper } from 'app/common/helper';
import { Constant, FIELD_COMPONENT, MODULE_NAME } from 'app/config/constants';
import { DialogConfirmComponent } from 'app/dialog/dialog-confirm/dialog-confirm.component';
import { DialogMessageComponent } from 'app/dialog/dialog-message/dialog-message.component';
import { DialogTranslateTicketComponent } from 'app/dialog/dialog-translate-ticket/dialog-translate-ticket.component';
import { ApplicationDTO } from 'app/model/entity/ticket-editor/dto/application-DTO';
import { SaleTicket } from 'app/model/entity/ticket-editor/set-ticket';
import { CombinationTicket, SaleTicketDetail } from 'app/model/entity/ticket-editor/set-ticket-detail';
import { CommonService } from 'app/service/common.service';
import { DataService } from 'app/service/data.service';
import { DialogService } from 'app/service/dialog.service';
import { MenuActionService } from 'app/service/menu-action.service';
import { TicketEditorService } from 'app/service/ticket-editor.service';
import { ApplicationService } from 'app/service/ticket-editor/application.service';
import _ from 'lodash';
import moment from 'moment';
import { Subscription, forkJoin } from 'rxjs';
@Component({
  selector: 'ticket-editor-tab-combination',
  templateUrl: './ticket-editor-tab-combination.component.html',
  styleUrls: ['./ticket-editor-tab-combination.component.scss']
})
export class TicketEditorTabCombinationComponent implements OnInit, OnDestroy {
  @Input() tabSelected: number; // tab selected
  @Input() informationAccount: any;
  Constant = Constant;
  MODULE_NAME = MODULE_NAME;
  FIELD_COMPONENT = FIELD_COMPONENT;
  PATH_ANGLE_DOUBLE_RIGHT = Constant.PATH_ANGLE_DOUBLE_RIGHT;
  subscriptions: Array<Subscription> = new Array<Subscription>(); //array subscription
  listCombinationTicket = []; // List of tickets
  listSaleTicket: Array<SaleTicket>; // List of ticketsThe list of tickets that have made the set
  setTicketSelected: SaleTicket; // Set ticket is selected
  ticketSetDetail: SaleTicketDetail; // Data detail of set ticket
  combinationTicketClone: Array<CombinationTicket> = new Array<CombinationTicket>(); // Data detail of set ticket
  placeholderSelectDate: string; // Placeholder for input type date
  isEditSaleTicket = false; // Show method edit set ticket
  isEnlargePreview: boolean = false; // true if preview on
  isEditSaleTicketDetail: boolean = false; // Check editing set ticket detail
  formSaleTicket: FormGroup; // Form sale ticket
  isDragDrop: boolean;
  draggedItem: any;
  languages: any = new Array<any>();
  time = '00:00';
  timezone: any;
  public languageKey: string; //language key
  updateScheduleAtValue: any;
  isClear: boolean;
  @Output() sizePreview = new EventEmitter(); // Send event size preview to parent component
  titleFormData: string;
  titleEditSetTicket: string;
  titleEditReservation: string;
  titleAddReservation: string;
  titleNewRegistrationSetTicket: string;
  titleDuplicateSetTicket: string;
  isEditType: boolean;
  listApp: ApplicationDTO[] = []; // list Application
  formatDate: string;
  constructor(
    private menuActionService: MenuActionService,
    private translateService: TranslateService,
    private dataService: DataService,
    private ticketEditorService: TicketEditorService,
    private formBuilder: FormBuilder,
    private dialogService: DialogService,
    private commonService: CommonService,
    private applicationService: ApplicationService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    // Action menu combination
    this.subscriptions.push(
      // add new set ticket
      this.menuActionService.actionAdd.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TicketEditorComponent] && this.tabSelected == Constant.COMBINATION_ENUM) {
          this.addNewSaleTicketElement();
        }
      }),
      // Edit set ticket
      this.menuActionService.actionEdit.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TicketEditorComponent] && this.tabSelected == Constant.COMBINATION_ENUM) {
          this.editDetailSaleTicket();
        }
      }),
      // Duplicate set ticket
      this.menuActionService.actionDuplicate.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TicketEditorComponent] && this.tabSelected == Constant.COMBINATION_ENUM) {
          this.duplicateSaleTicket();
        }
      }),
      // Delete set ticket
      this.menuActionService.actionDelete.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TicketEditorComponent] && this.tabSelected == Constant.COMBINATION_ENUM) {
          this.deleteSaleTicket();
        }
      }),
      // add new set ticket
      this.menuActionService.actionAddReservation.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TicketEditorComponent] && this.tabSelected == Constant.COMBINATION_ENUM) {
          this.addSaleTicketUpdate();
        }
      }),
      // Edit set ticket
      this.menuActionService.actionEditReservation.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TicketEditorComponent] && this.tabSelected == Constant.COMBINATION_ENUM) {
          this.editSaleTicketUpdate();
        }
      }),
      // Delete set ticket
      this.menuActionService.actionDeleteReservation.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TicketEditorComponent] && this.tabSelected == Constant.COMBINATION_ENUM) {
          this.deleteSaleTicketUpdate();
        }
      }),
      this.menuActionService.actionTranslateTicket.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TicketEditorComponent] && this.tabSelected == Constant.COMBINATION_ENUM) {
          this.translateTicket();
        }
      })
    );
    // Set text placeholder
    this.placeholderSelectDate = this.translateService.instant('ticket-editor.combination.select-date');

    // Get language
    this.translateService.onLangChange.subscribe(() => {
      // Translate title
      this.placeholderSelectDate = this.translateService.instant('ticket-editor.combination.select-date');
    });

    this.subscriptions.push(
      this.translateService.onLangChange.subscribe(() => {
        // multi language
        this.languageKey = this.commonService.getCommonObject().setting?.language;
        this.changeTitleFormData();
      })
    );
    this.titleFormData = '';
  }

  async ngOnInit(): Promise<void> {
    this.languageKey = this.commonService.getCommonObject().setting?.language;
    //Get data
    this.initFormSaleTicket();
    this.getListSaleTicket();
    this.ticketEditorService.getTickets(this.informationAccount).subscribe(
      data => {
        this.listCombinationTicket = data;
      },
      error => {
        return;
      }
    );
    await this.getAllApplication();
    this.timezone = this.commonService
      .getCommonObject()
      .setting.timezone.name.split(' ')[0]
      .replace(')', '')
      .replace('(', '')
      .replace('GMT', '');
    if (!this.timezone) {
      this.timezone = 'Z';
    }
    this.formatDate = 'yyyy-MM-dd';
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  /**
   * Get list sale ticket
   * @param setTicketId
   */
  getListSaleTicket(setTicketId?: number): void {
    this.ticketEditorService.getSalesTickets(this.informationAccount).subscribe(data => {
      this.listSaleTicket = data;
      if (this.listSaleTicket.length) {
        // Get detail set ticket
        setTicketId = setTicketId ? setTicketId : this.listSaleTicket[0].saleTicketId;
        this.setTicketSelected = this.setTicketSelected ? this.setTicketSelected : this.listSaleTicket[0];
        this.getDetailTicketSet(setTicketId);
      } else {
        this.setTicketSelected = null;
        this.ticketSetDetail = null;
        this.dataService.sendData([Constant.IS_DISABLE_EDIT_SCHEDULE, true]);
      }
    }),
      error => Helper.getErrorTicket(error, this.translateService, this.dialogService);
  }

  /**
   * Get detail set ticket
   * @param setTicketId
   */
  getDetailTicketSet(setTicketId: number, event?): void {
    if (this.isEditSaleTicket || event?.target?.id === 'checkBoxTicketSet') {
      return;
    }
    this.time = '00:00';
    forkJoin({
      saleTicketDetail: this.ticketEditorService.getSalesTicket(this.informationAccount, setTicketId),
      saleTicketDetailUpdate: this.ticketEditorService.getSalesTicketUpdate(this.informationAccount, setTicketId)
    }).subscribe(
      data => {
        const index = this.listSaleTicket.findIndex(saleTicket => saleTicket.saleTicketId == setTicketId);
        this.setTicketSelected = this.listSaleTicket[index];
        this.setTicketSelected.saleTicketDetail = data.saleTicketDetail;
        this.setTicketSelected.saleTicketDetailReserved = data.saleTicketDetailUpdate;
        if (this.setTicketSelected.saleTicketDetailReserved.updateScheduleAt) {
          const tmp = this.setTicketSelected.saleTicketDetailReserved.updateScheduleAt.split('T');
          const time = tmp[1].split(':');
          this.time = `${time[0]}:${time[1]}`;
          this.dataService.sendData([Constant.IS_DISABLE_EDIT_SCHEDULE, false]);
        } else {
          this.dataService.sendData([Constant.IS_DISABLE_EDIT_SCHEDULE, true]);
        }
        this.ticketSetDetail = this.setTicketSelected.isChooseDetailUpdate
          ? this.setTicketSelected.saleTicketDetailReserved
          : this.setTicketSelected.saleTicketDetail;
        this.combinationTicketClone = _.cloneDeep(this.ticketSetDetail.combinationTicket);
      },
      error => {
        Helper.getErrorTicket(error, this.translateService, this.dialogService);
        return;
      }
    );
  }

  /**
   * get list application
   * @param appSelect
   */
  async getAllApplication(): Promise<void> {
    return new Promise<void>(resolve => {
      this.applicationService.findAllApplication().subscribe(res => {
        let listAppDB = Helper.convertResApplication(res);
        if (listAppDB && listAppDB.length) {
          this.listApp = listAppDB.filter(e => e.appId);
        }
        resolve();
      });
    });
  }

  /**
   * Check or uncheck a set ticket
   * @param index
   */
  async changeCheckedTicketSet(index: number, e): Promise<void> {
    e.stopPropagation();
    this.listSaleTicket[index].isSelected = !this.listSaleTicket[index].isSelected;
    const listSaleTicketIsChecked = this.listSaleTicket.filter(e => e.isSelected);
    let checkPlanningToUpdate = true;
    if (!listSaleTicketIsChecked || listSaleTicketIsChecked.length == 0) {
      checkPlanningToUpdate = false;
    } else {
      for (let i = 0; i < listSaleTicketIsChecked.length; i++) {
        const element = listSaleTicketIsChecked[i];
        await this.ticketEditorService
          .getSalesTicketUpdate(this.informationAccount, element.saleTicketId)
          .toPromise()
          .then(res => {
            if (!res || Helper.isObjectEmpty(res)) {
              checkPlanningToUpdate = false;
            }
          })
          .catch(error => {
            checkPlanningToUpdate = false;
          });

        if (!checkPlanningToUpdate) {
          break;
        }
      }
    }
    this.dataService.sendData([Constant.THERE_ARE_PLANS_FOR_UPDATES, checkPlanningToUpdate]);
  }

  /**
   * Add new set ticket element
   */
  async addNewSaleTicketElement(): Promise<void> {
    this.titleFormData = this.translateService.instant('menu-ticket-editor.edit.new-registration-set-ticket');
    this.titleNewRegistrationSetTicket = this.titleFormData;
    this.changeDetailUpdate(false);
    const data = new SaleTicketDetail();
    data.isDisplayInformationTicketName = true;
    data.isDisplayInformationCompanyName = true;
    data.isDisplayInformationSubtitle = true;
    data.isDisplayInformationNote = true;
    data.isDisplayInformationNote1 = true;
    data.isDisplayInformationNote2 = true;
    data.isDisplayInformationNote3 = true;
    data.isDisplayInformationSalesName1 = true;
    data.isDisplayInformationSalesName2 = true;
    data.isDisplayInformationSalesName3 = true;
    await [(this.ticketSetDetail = data)];
    this.formSaleTicket.get('companyNameEN').disable();
    this.formSaleTicket.get('companyNameJP').disable();
    // Select row set ticket
    this.isEditSaleTicketDetail = true;
    // this.createFormValidate();
    this.dataService.sendData([Constant.IS_EDITING_COMBINATION, true]);
  }

  /**
   * duplicateSaleTicket
   */
  duplicateSaleTicket(): void {
    if (!this.ticketSetDetail) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('ticket-editor.choose-sale-ticket')
        }
      });
      return;
    }
    this.titleFormData = this.translateService.instant('menu-ticket-editor.edit.duplicate-set-ticket');
    this.titleDuplicateSetTicket = this.titleFormData;
    let saleTicketDuplicate = _.cloneDeep(this.ticketSetDetail);
    saleTicketDuplicate.saleTicketId = null;
    this.ticketSetDetail = saleTicketDuplicate;
    this.ticketSetDetail.isDisplayInformationTicketName = true;
    this.ticketSetDetail.isDisplayInformationSubtitle = true;
    this.ticketSetDetail.isDisplayInformationNote = true;
    this.ticketSetDetail.isDisplayInformationNote1 = true;
    this.ticketSetDetail.isDisplayInformationNote2 = true;
    this.ticketSetDetail.isDisplayInformationNote3 = true;
    this.ticketSetDetail.isDisplayInformationSalesName1 = true;
    this.ticketSetDetail.isDisplayInformationSalesName2 = true;
    this.ticketSetDetail.isDisplayInformationSalesName3 = true;
    this.isEditSaleTicketDetail = true;
    this.formSaleTicket.get('companyNameEN').disable();
    this.formSaleTicket.get('companyNameJP').disable();

    this.fillValueToForm(this.ticketSetDetail);
    this.dataService.sendData([Constant.IS_EDITING_COMBINATION, true]);
  }

  /**
   * Delete set ticket
   */
  deleteSaleTicket(): void {
    // Get list set ticket selected
    const listSaleTicketIdSelected = this.listSaleTicket.filter(setTicket => setTicket.isSelected).map(e => e.saleTicketId);
    if (listSaleTicketIdSelected.length < 1) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('ticket-editor.choose-sale-ticket')
        }
      });
      return;
    }

    this.dialogService.showDialog(
      DialogConfirmComponent,
      {
        data: {
          text: this.translateService.instant('ticket-editor.combination.confirm-delete-checked-set-ticket'),
          button1: this.translateService.instant('ticket-editor.yes'),
          button2: this.translateService.instant('ticket-editor.btn-no')
        }
      },
      result => {
        if (!result) {
          return;
        }
        let payloads = [];
        for (let saleTicketId of listSaleTicketIdSelected) {
          let payload = {
            saleTicketId: saleTicketId
          };
          payloads.push(payload);
        }
        this.ticketEditorService.deleteSaleTickets(this.informationAccount, payloads).subscribe(
          (data: any) => {
            const saleTicketId = data;
            this.isEditSaleTicket = false;
            this.getListSaleTicket();
          },
          (error: any) => {
            return;
          }
        );
      }
    );
  }

  /**
   * Edit detail set ticket element
   */
  editDetailSaleTicket(): void {
    if (!this.ticketSetDetail) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('ticket-editor.choose-sale-ticket')
        }
      });
      return;
    }
    this.titleFormData = this.translateService.instant('menu-ticket-editor.edit.edit-set-ticket');
    this.titleEditSetTicket = this.titleFormData;
    this.isEditType = true;
    this.changeDetailUpdate(false);
    this.isEditSaleTicketDetail = true;
    this.ticketSetDetail.isDisplayInformationTicketName = true;
    this.ticketSetDetail.isDisplayInformationCompanyName = true;
    this.ticketSetDetail.isDisplayInformationSubtitle = true;
    this.ticketSetDetail.isDisplayInformationNote = true;
    this.ticketSetDetail.isDisplayInformationNote1 = true;
    this.ticketSetDetail.isDisplayInformationNote2 = true;
    this.ticketSetDetail.isDisplayInformationNote3 = true;
    this.ticketSetDetail.isDisplayInformationSalesName1 = true;
    this.ticketSetDetail.isDisplayInformationSalesName2 = true;
    this.ticketSetDetail.isDisplayInformationSalesName3 = true;
    let companyNameENControl = this.formSaleTicket.get('companyNameEN');
    let companyNameJPControl = this.formSaleTicket.get('companyNameJP');
    companyNameENControl.clearValidators();
    companyNameJPControl.clearValidators();
    this.fillValueToForm(this.ticketSetDetail);
    this.dataService.sendData([Constant.IS_EDITING_COMBINATION, true]);
  }

  /**
   * Update detail set ticket
   */
  async updateDetailSaleTicket(): Promise<void> {
    if (this.titleAddReservation || this.titleEditReservation) {
      this.formSaleTicket.get('appId').disable();
    }
    if (
      this.formSaleTicket.valid &&
      (this.formSaleTicket.value?.adultSelection ||
        this.formSaleTicket.value?.custom1Selection ||
        this.formSaleTicket.value?.custom2Selection ||
        this.formSaleTicket.value?.custom3Selection)
    ) {
      const combinationTicket = this.ticketSetDetail.combinationTicket;
      if (!combinationTicket.length) {
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-error.title'),
            text: this.translateService.instant('ticket-editor.combination.error-combinationTicket')
          }
        });
        return;
      } else if (
        combinationTicket.some((item, index) => combinationTicket.findIndex(other => other.ticketId === item.ticketId) !== index)
      ) {
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-error.title'),
            text: this.translateService.instant('ticket-editor.combination.error-duplicate-ticketId')
          }
        });
        return;
      }
      this.isDragDrop = false;
      this.getDataFromForm();
    } else {
      this.markTheElement(this.formSaleTicket);
    }
  }

  /**
   * getDataFromForm
   */
  getDataFromForm(): void {
    const formValue = this.formSaleTicket.value;
    let payload: any;
    const combinationTicket = this.ticketSetDetail.combinationTicket
      ? this.ticketSetDetail.combinationTicket.map((e, index) => ({
          ticketId: e.ticketId,
          displayPriority: index + 1
        }))
      : [];

    let subtitle = {};
    if (formValue.subtitleJP || formValue.subtitleEN) {
      subtitle = {
        ja: formValue.subtitleJP ? formValue.subtitleJP : '',
        en: formValue.subtitleEN ? formValue.subtitleEN : ''
      };
    }

    let note = {};
    if (formValue.noteJP || formValue.noteEN) {
      note = {
        ja: formValue.noteJP ? formValue.noteJP : '',
        en: formValue.noteEN ? formValue.noteEN : ''
      };
    }

    let custom1Name = {};
    if (formValue.custom1NameJP && formValue.custom1NameEN) {
      custom1Name = {
        ja: formValue.custom1NameJP ? formValue.custom1NameJP : null,
        en: formValue.custom1NameEN ? formValue.custom1NameEN : null
      };
    } else if (formValue.custom1NameJP) {
      custom1Name = {
        ja: formValue.custom1NameJP ? formValue.custom1NameJP : null
      };
    } else if (formValue.custom1NameEN) {
      custom1Name = {
        en: formValue.custom1NameEN ? formValue.custom1NameEN : null
      };
    }

    let custom1Note = {};
    if (formValue.custom1NoteJP && formValue.custom1NoteEN) {
      custom1Note = {
        ja: formValue.custom1NoteJP ? formValue.custom1NoteJP : null,
        en: formValue.custom1NoteEN ? formValue.custom1NoteEN : null
      };
    } else if (formValue.custom1NoteJP) {
      custom1Note = {
        ja: formValue.custom1NoteJP ? formValue.custom1NoteJP : null
      };
    } else if (formValue.custom1NoteEN) {
      custom1Note = {
        en: formValue.custom1NoteEN ? formValue.custom1NoteEN : null
      };
    }

    let custom2Name = {};
    if (formValue.custom2NameJP && formValue.custom2NameEN) {
      custom2Name = {
        ja: formValue.custom2NameJP ? formValue.custom2NameJP : null,
        en: formValue.custom2NameEN ? formValue.custom2NameEN : null
      };
    } else if (formValue.custom2NameJP) {
      custom2Name = {
        ja: formValue.custom2NameJP ? formValue.custom2NameJP : null
      };
    } else if (formValue.custom2NameEN) {
      custom2Name = {
        en: formValue.custom2NameEN ? formValue.custom2NameEN : null
      };
    }

    let custom2Note = {};
    if (formValue.custom2NoteJP && formValue.custom2NoteEN) {
      custom2Note = {
        ja: formValue.custom2NoteJP ? formValue.custom2NoteJP : null,
        en: formValue.custom2NoteEN ? formValue.custom2NoteEN : null
      };
    } else if (formValue.custom2NoteJP) {
      custom2Note = {
        ja: formValue.custom2NoteJP ? formValue.custom2NoteJP : null
      };
    } else if (formValue.custom2NoteEN) {
      custom2Note = {
        en: formValue.custom2NoteEN ? formValue.custom2NoteEN : null
      };
    }

    let custom3Name = {};
    if (formValue.custom3NameJP && formValue.custom3NameEN) {
      custom3Name = {
        ja: formValue.custom3NameJP ? formValue.custom3NameJP : null,
        en: formValue.custom3NameEN ? formValue.custom3NameEN : null
      };
    } else if (formValue.custom3NameJP) {
      custom3Name = {
        ja: formValue.custom3NameJP ? formValue.custom3NameJP : null
      };
    } else if (formValue.custom3NameEN) {
      custom3Name = {
        en: formValue.custom3NameEN ? formValue.custom3NameEN : null
      };
    }

    let custom3Note = {};
    if (formValue.custom3NoteJP && formValue.custom3NoteEN) {
      custom3Note = {
        ja: formValue.custom3NoteJP ? formValue.custom3NoteJP : null,
        en: formValue.custom3NoteEN ? formValue.custom3NoteEN : null
      };
    } else if (formValue.custom3NoteJP) {
      custom3Note = {
        ja: formValue.custom3NoteJP ? formValue.custom3NoteJP : null
      };
    } else if (formValue.custom3NoteEN) {
      custom3Note = {
        en: formValue.custom3NoteEN ? formValue.custom3NoteEN : null
      };
    }
    if (this.titleFormData) {
      switch (this.titleFormData) {
        case this.titleNewRegistrationSetTicket:
          // Add sales ticket
          payload = {
            saleTicketName: {
              ja: formValue.saleTicketNameJP,
              en: formValue.saleTicketNameEN
            },
            appId: formValue.appId,
            operatorId: +this.informationAccount.compnanyid,
            combinationTicket: combinationTicket,
            subtitle: subtitle,
            note: note,
            saleStartAt: moment(formValue.saleStartAt).format('YYYY-MM-DD'),
            saleEndAt: formValue.saleEndAt ? moment(formValue.saleEndAt).format('YYYY-MM-DD') : undefined,
            priceAdult: formValue.priceAdult,
            priceType: formValue.priceType,
            custom1Name: custom1Name,
            custom1Note: custom1Note,
            custom2Name: custom2Name,
            custom2Note: custom2Note,
            custom3Name: custom3Name,
            custom3Note: custom3Note,
            priceCustom1: formValue.priceCustom1 ?? undefined,
            priceCustom2: formValue.priceCustom2 ?? undefined,
            priceCustom3: formValue.priceCustom3 ?? undefined,
            refundFeeAdult: formValue.refundFeeAdult ?? undefined,
            refundFeeCustom1: formValue.refundFeeCustom1 ?? undefined,
            refundFeeCustom2: formValue.refundFeeCustom2 ?? undefined,
            refundFeeCustom3: formValue.refundFeeCustom3 ?? undefined,
            refundFeeTicket: formValue.refundFeeTicket ?? undefined,
            minCountAdult: formValue.minCountAdult ?? undefined,
            maxCountAdult: formValue.maxCountAdult ?? undefined,
            minCountCustom1: formValue.minCountCustom1 ?? undefined,
            maxCountCustom1: formValue.maxCountCustom1 ?? undefined,
            minCountCustom2: formValue.minCountCustom2 ?? undefined,
            maxCountCustom2: formValue.maxCountCustom2 ?? undefined,
            minCountCustom3: formValue.minCountCustom3 ?? undefined,
            maxCountCustom3: formValue.maxCountCustom3 ?? undefined,
            availablePeriod: formValue.availablePeriod ?? undefined,
            availablePeriodUnit: formValue.availablePeriodUnit ?? undefined,
            availableDeadlineAt: formValue.availableDeadlineAt ? moment(formValue.availableDeadlineAt).format('YYYY-MM-DD') : undefined,
            refundPeriod: formValue.refundPeriod ?? undefined,
            refundPeriodUnit: formValue.refundPeriodUnit ?? undefined,
            refundDeadlineAt: formValue.refundDeadlineAt ? moment(formValue.refundDeadlineAt).format('YYYY-MM-DD') : undefined
          };
          if (!Object.keys(subtitle).length) {
            delete payload.subtitle;
          }
          if (!Object.keys(note).length) {
            delete payload.note;
          }
          if (!Object.keys(custom1Name).length) {
            delete payload.custom1Name;
          }
          if (!Object.keys(custom1Note).length) {
            delete payload.custom1Note;
          }
          if (!Object.keys(custom2Name).length) {
            delete payload.custom2Name;
          }
          if (!Object.keys(custom2Note).length) {
            delete payload.custom2Note;
          }
          if (!Object.keys(custom3Name).length) {
            delete payload.custom3Name;
          }
          if (!Object.keys(custom3Note).length) {
            delete payload.custom3Note;
          }
          this.ticketEditorService.postSaleTicket(this.informationAccount, payload).subscribe(
            data => {
              this.isEditSaleTicketDetail = false;
              this.isEditType = false;
              this.titleFormData = '';
              this.getListSaleTicket();
              this.initFormSaleTicket();
            },
            error => {
              Helper.getErrorTicket(error, this.translateService, this.dialogService);
              this.formSaleTicket.patchValue(formValue);
              return;
            }
          );
          break;
        case this.titleDuplicateSetTicket:
          //duplicate set ticket
          payload = {
            saleTicketName: {
              ja: formValue.saleTicketNameJP,
              en: formValue.saleTicketNameEN
            },
            appId: formValue.appId,
            operatorId: +this.informationAccount.compnanyid,
            combinationTicket: combinationTicket,
            subtitle: subtitle,
            note: note,
            saleStartAt: moment(formValue.saleStartAt).format('YYYY-MM-DD'),
            saleEndAt: formValue.saleEndAt ? moment(formValue.saleEndAt).format('YYYY-MM-DD') : undefined,
            priceAdult: formValue.priceAdult,
            priceType: formValue.priceType,
            custom1Name: custom1Name,
            custom1Note: custom1Note,
            custom2Name: custom2Name,
            custom2Note: custom2Note,
            custom3Name: custom3Name,
            custom3Note: custom3Note,
            priceCustom1: formValue.priceCustom1 ?? undefined,
            priceCustom2: formValue.priceCustom2 ?? undefined,
            priceCustom3: formValue.priceCustom3 ?? undefined,
            refundFeeAdult: formValue.refundFeeAdult ?? undefined,
            refundFeeCustom1: formValue.refundFeeCustom1 ?? undefined,
            refundFeeCustom2: formValue.refundFeeCustom2 ?? undefined,
            refundFeeCustom3: formValue.refundFeeCustom3 ?? undefined,
            refundFeeTicket: formValue.refundFeeTicket ?? undefined,
            minCountAdult: formValue.minCountAdult ?? undefined,
            maxCountAdult: formValue.maxCountAdult ?? undefined,
            minCountCustom1: formValue.minCountCustom1 ?? undefined,
            maxCountCustom1: formValue.maxCountCustom1 ?? undefined,
            minCountCustom2: formValue.minCountCustom2 ?? undefined,
            maxCountCustom2: formValue.maxCountCustom2 ?? undefined,
            minCountCustom3: formValue.minCountCustom3 ?? undefined,
            maxCountCustom3: formValue.maxCountCustom3 ?? undefined,
            availablePeriod: formValue.availablePeriod ?? undefined,
            availablePeriodUnit: formValue.availablePeriodUnit ?? undefined,
            availableDeadlineAt: formValue.availableDeadlineAt ? moment(formValue.availableDeadlineAt).format('YYYY-MM-DD') : undefined,
            refundPeriod: formValue.refundPeriod ?? undefined,
            refundPeriodUnit: formValue.refundPeriodUnit ?? undefined,
            refundDeadlineAt: formValue.refundDeadlineAt ? moment(formValue.refundDeadlineAt).format('YYYY-MM-DD') : undefined
          };
          if (!Object.keys(subtitle).length) {
            delete payload.subtitle;
          }
          if (!Object.keys(note).length) {
            delete payload.note;
          }
          if (!Object.keys(custom1Name).length) {
            delete payload.custom1Name;
          }
          if (!Object.keys(custom1Note).length) {
            delete payload.custom1Note;
          }
          if (!Object.keys(custom2Name).length) {
            delete payload.custom2Name;
          }
          if (!Object.keys(custom2Note).length) {
            delete payload.custom2Note;
          }
          if (!Object.keys(custom3Name).length) {
            delete payload.custom3Name;
          }
          if (!Object.keys(custom3Note).length) {
            delete payload.custom3Note;
          }
          this.ticketEditorService.postSaleTicket(this.informationAccount, payload).subscribe(
            data => {
              this.isEditSaleTicketDetail = false;
              this.isEditType = false;
              this.titleFormData = '';
              this.getListSaleTicket();
              this.initFormSaleTicket();
            },
            error => {
              Helper.getErrorTicket(error, this.translateService, this.dialogService);
              this.formSaleTicket.patchValue(formValue);
              return;
            }
          );
          break;
        case this.titleEditSetTicket:
          // Edit sales ticket
          payload = {
            saleTicketId: this.setTicketSelected.saleTicketId ?? null,
            isSale: formValue.isSale ? (formValue.isSale == 'true' ? true : false) : null,
            saleTicketName: {
              ja: formValue.saleTicketNameJP,
              en: formValue.saleTicketNameEN
            },
            subtitle: Object.keys(subtitle).length ? subtitle : null,
            note: Object.keys(note).length ? note : null,
            saleStartAt: moment(formValue.saleStartAt).format('YYYY-MM-DD'),
            saleEndAt: formValue.saleEndAt ? moment(formValue.saleEndAt).format('YYYY-MM-DD') : null,
            custom1Name: Object.keys(custom1Name)?.length ? custom1Name : null,
            custom1Note: Object.keys(custom1Note)?.length ? custom1Note : null,
            custom2Name: Object.keys(custom2Name)?.length ? custom2Name : null,
            custom2Note: Object.keys(custom2Note)?.length ? custom2Note : null,
            custom3Name: Object.keys(custom3Name)?.length ? custom3Name : null,
            custom3Note: Object.keys(custom3Note)?.length ? custom3Note : null
          };
          this.ticketEditorService.putSaleTicket(this.informationAccount, payload).subscribe(
            data => {
              this.isEditSaleTicketDetail = false;
              this.isEditType = false;
              this.titleFormData = '';
              this.getListSaleTicket();
              this.initFormSaleTicket();
            },
            error => {
              Helper.getErrorTicket(error, this.translateService, this.dialogService);
              this.formSaleTicket.patchValue(formValue);
              return;
            }
          );
          break;
        case this.titleAddReservation:
        case this.titleEditReservation:
          payload = {
            saleTicketId: this.setTicketSelected.saleTicketId,
            saleTicketName: {
              ja: formValue.saleTicketNameJP,
              en: formValue.saleTicketNameEN
            },
            subtitle: Object.keys(subtitle).length ? subtitle : null,
            note: Object.keys(note).length ? note : null,
            priceAdult: formValue.priceAdult ?? null,
            custom1Name: Object.keys(custom1Name)?.length ? custom1Name : null,
            custom1Note: Object.keys(custom1Note)?.length ? custom1Note : null,
            custom2Name: Object.keys(custom2Name)?.length ? custom2Name : null,
            custom2Note: Object.keys(custom2Note)?.length ? custom2Note : null,
            custom3Name: Object.keys(custom3Name)?.length ? custom3Name : null,
            custom3Note: Object.keys(custom3Note)?.length ? custom3Note : null,
            priceCustom1: formValue.priceCustom1 ?? null,
            priceCustom2: formValue.priceCustom2 ?? null,
            priceCustom3: formValue.priceCustom3 ?? null,
            refundFeeAdult: formValue.refundFeeAdult ?? null,
            refundFeeCustom1: formValue.refundFeeCustom1 ?? null,
            refundFeeCustom2: formValue.refundFeeCustom2 ?? null,
            refundFeeCustom3: formValue.refundFeeCustom3 ?? null,
            refundFeeTicket: formValue.refundFeeTicket ?? null,
            minCountAdult: formValue.minCountAdult ?? null,
            maxCountAdult: formValue.maxCountAdult ?? null,
            minCountCustom1: formValue.minCountCustom1 ?? null,
            maxCountCustom1: formValue.maxCountCustom1 ?? null,
            minCountCustom2: formValue.minCountCustom2 ?? null,
            maxCountCustom2: formValue.maxCountCustom2 ?? null,
            minCountCustom3: formValue.minCountCustom3 ?? null,
            maxCountCustom3: formValue.maxCountCustom3 ?? null,
            availablePeriod: formValue.availablePeriod ?? null,
            availablePeriodUnit: formValue.availablePeriodUnit ?? null,
            availableDeadlineAt: formValue.availableDeadlineAt ? moment(formValue.availableDeadlineAt).format('YYYY-MM-DD') : null,
            refundPeriod: formValue.refundPeriod ?? null,
            refundPeriodUnit: formValue.refundPeriodUnit ?? null,
            refundDeadlineAt: formValue.refundDeadlineAt ? moment(formValue.refundDeadlineAt).format('YYYY-MM-DD') : null
          };
          const selectedTime = new Date(formValue.updateScheduleAt);
          const dateInfoEditor = {
            year: selectedTime.getFullYear(),
            month: selectedTime.getMonth(),
            day: selectedTime.getDate(),
            hour: parseInt(this.time.split(':')[0]),
            minute: parseInt(this.time.split(':')[1]),
            second: 0
          };
          const momentObjectEditor = moment(dateInfoEditor).format('YYYY-MM-DDTHH:mm:ss');
          payload[`updateScheduleAt`] = momentObjectEditor + this.timezone;
          this.ticketEditorService.postSaleTicketUpdate(this.informationAccount, payload).subscribe(
            data => {
              this.isEditSaleTicketDetail = false;
              this.isEditType = false;
              this.titleFormData = '';
              this.getListSaleTicket();
              this.initFormSaleTicket();
            },
            error => {
              Helper.getErrorTicket(error, this.translateService, this.dialogService);
              this.formSaleTicket.patchValue(formValue);
              this.formSaleTicket.get('appId').clearValidators();
              return;
            }
          );
          break;
      }
    }
    this.initFormSaleTicket();
    this.dataService.sendData([Constant.IS_EDITING_COMBINATION, false]);
  }

  /**
   * fillValueToForm
   * @param ticketSetDetail
   */
  fillValueToForm(ticketSetDetail: SaleTicketDetail): void {
    this.formSaleTicket.patchValue({
      saleTicketId: ticketSetDetail?.saleTicketId,
      saleTicketNameEN: ticketSetDetail?.saleTicketName?.en,
      saleTicketNameJP: ticketSetDetail?.saleTicketName?.ja,
      appId: ticketSetDetail?.appId,
      appName: ticketSetDetail?.appName,
      companyId: ticketSetDetail?.companyId,
      companyNameEN: ticketSetDetail?.companyName?.en,
      companyNameJP: ticketSetDetail?.companyName?.ja,
      subtitleEN: ticketSetDetail?.subtitle?.en,
      subtitleJP: ticketSetDetail?.subtitle?.ja,
      noteEN: ticketSetDetail?.note?.en,
      noteJP: ticketSetDetail?.note?.ja,
      saleStartAt: ticketSetDetail?.saleStartAt,
      isSale: ticketSetDetail?.isSale ? 'true' : 'false',
      currencyUnit: ticketSetDetail?.currencyUnit,
      priceType: ticketSetDetail?.priceType,
      adultSelection: ticketSetDetail?.priceAdult ? true : false,
      custom1Selection: ticketSetDetail?.priceCustom1 ? true : false,
      custom2Selection: ticketSetDetail?.priceCustom2 ? true : false,
      custom3Selection: ticketSetDetail?.priceCustom3 ? true : false,
      priceAdult: ticketSetDetail?.priceAdult,
      custom1NameEN: ticketSetDetail?.custom1Name?.en,
      custom1NameJP: ticketSetDetail?.custom1Name?.ja,
      custom1NoteEN: ticketSetDetail?.custom1Note?.en,
      custom1NoteJP: ticketSetDetail?.custom1Note?.ja,
      custom2NameEN: ticketSetDetail?.custom2Name?.en,
      custom2NameJP: ticketSetDetail?.custom2Name?.ja,
      custom2NoteEN: ticketSetDetail?.custom2Note?.en,
      custom2NoteJP: ticketSetDetail?.custom2Note?.ja,
      custom3NameEN: ticketSetDetail?.custom3Name?.en,
      custom3NameJP: ticketSetDetail?.custom3Name?.ja,
      custom3NoteEN: ticketSetDetail?.custom3Note?.en,
      custom3NoteJP: ticketSetDetail?.custom3Note?.ja,
      priceCustom1: ticketSetDetail?.priceCustom1,
      priceCustom2: ticketSetDetail?.priceCustom2,
      priceCustom3: ticketSetDetail?.priceCustom3,
      refundFeeMethod: ticketSetDetail?.refundFeeTicket ? '2' : '1',
      refundFeeAdult: ticketSetDetail?.refundFeeAdult,
      refundFeeCustom1: ticketSetDetail?.refundFeeCustom1,
      refundFeeCustom2: ticketSetDetail?.refundFeeCustom2,
      refundFeeCustom3: ticketSetDetail?.refundFeeCustom3,
      refundFeeTicket: ticketSetDetail?.refundFeeTicket,
      minCountAdult: ticketSetDetail?.minCountAdult,
      maxCountAdult: ticketSetDetail?.maxCountAdult,
      minCountCustom1: ticketSetDetail?.minCountCustom1,
      maxCountCustom1: ticketSetDetail?.maxCountCustom1,
      minCountCustom2: ticketSetDetail?.minCountCustom2,
      maxCountCustom2: ticketSetDetail?.maxCountCustom2,
      minCountCustom3: ticketSetDetail?.minCountCustom3,
      maxCountCustom3: ticketSetDetail?.maxCountCustom3,
      availablePeriod: ticketSetDetail?.availablePeriod,
      availablePeriodUnit: ticketSetDetail?.availablePeriodUnit,
      refundPeriod: ticketSetDetail?.refundPeriod,
      refundPeriodUnit: ticketSetDetail?.refundPeriodUnit
    });
    if (ticketSetDetail?.updateScheduleAt) {
      this.formSaleTicket.patchValue({
        updateScheduleAt: ticketSetDetail?.updateScheduleAt
      });
    }
    if (ticketSetDetail?.saleEndAt) {
      this.formSaleTicket.patchValue({
        saleEndAt: ticketSetDetail?.saleEndAt
      });
    }
    if (ticketSetDetail?.availableDeadlineAt) {
      this.formSaleTicket.patchValue({
        availableDeadlineAt: ticketSetDetail?.availableDeadlineAt
      });
    }
    if (ticketSetDetail?.refundDeadlineAt) {
      this.formSaleTicket.patchValue({
        refundDeadlineAt: ticketSetDetail?.refundDeadlineAt
      });
    }
    if (this.formSaleTicket.value.adultSelection) {
      this.checkChangeCategory('0');
      this.validateOptinalFieds('priceAdult', null, null, 50, 999999, true);
      this.validateOptinalFieds('minCountAdult', null, null, 0, 9, true);
      this.validateOptinalFieds('maxCountAdult', null, null, 0, 9, true);
    }

    if (this.formSaleTicket.value.custom1Selection) {
      this.checkChangeCategory('1');
      this.validateOptinalFieds('custom1NameJP', 1, 4, null, null, true);
      this.validateOptinalFieds('custom1NameEN', 1, 25, null, null, true);
      this.validateOptinalFieds('custom1NoteJP', 1, 100, null, null, true);
      this.validateOptinalFieds('custom1NoteEN', 1, 100, null, null, true);
      this.validateOptinalFieds('priceCustom1', null, null, 50, 999999, true);
      this.validateOptinalFieds('minCountCustom1', null, null, 0, 9, true);
      this.validateOptinalFieds('maxCountCustom1', null, null, 0, 9, true);
    }
    if (this.formSaleTicket.value.custom2Selection) {
      this.checkChangeCategory('2');
      this.validateOptinalFieds('custom2NameJP', 1, 4, null, null, true);
      this.validateOptinalFieds('custom2NameEN', 1, 25, null, null, true);
      this.validateOptinalFieds('custom2NoteJP', 1, 100, null, null, true);
      this.validateOptinalFieds('custom2NoteEN', 1, 100, null, null, true);
      this.validateOptinalFieds('priceCustom2', null, null, 50, 999999, true);
      this.validateOptinalFieds('minCountCustom2', null, null, 0, 9, true);
      this.validateOptinalFieds('maxCountCustom2', null, null, 0, 9, true);
    }
    if (this.formSaleTicket.value.custom3Selection) {
      this.checkChangeCategory('3');
      this.validateOptinalFieds('custom3NameJP', 1, 4, null, null, true);
      this.validateOptinalFieds('custom3NameEN', 1, 25, null, null, true);
      this.validateOptinalFieds('custom3NoteJP', 1, 100, null, null, true);
      this.validateOptinalFieds('custom3NoteEN', 1, 100, null, null, true);
      this.validateOptinalFieds('priceCustom3', null, null, 50, 999999, true);
      this.validateOptinalFieds('minCountCustom3', null, null, 0, 9, true);
      this.validateOptinalFieds('maxCountCustom3', null, null, 0, 9, true);
    }
  }

  /**
   * Cancel edit detail set ticket
   */
  cancelDetailSaleTicket(): void {
    this.isEditSaleTicketDetail = this.setTicketSelected ? false : undefined;
    this.isDragDrop = false;
    this.dataService.sendData([Constant.IS_EDITING_COMBINATION, false]);
    this.initFormSaleTicket();
    if (this.setTicketSelected) {
      this.getDetailTicketSet(this.setTicketSelected.saleTicketId);
      this.ticketSetDetail.combinationTicket = this.combinationTicketClone;
      this.ticketSetDetail.isDisplayInformationTicketName = false;
      this.ticketSetDetail.isDisplayInformationCompanyName = false;
      this.ticketSetDetail.isDisplayInformationSubtitle = false;
      this.ticketSetDetail.isDisplayInformationNote = false;
      this.ticketSetDetail.isDisplayInformationNote1 = false;
      this.ticketSetDetail.isDisplayInformationNote2 = false;
      this.ticketSetDetail.isDisplayInformationNote3 = false;
      this.ticketSetDetail.isDisplayInformationSalesName1 = false;
      this.ticketSetDetail.isDisplayInformationSalesName2 = false;
      this.ticketSetDetail.isDisplayInformationSalesName3 = false;
    } else {
      this.ticketSetDetail = undefined;
    }
    this.isEditType = false;
    this.titleFormData = '';
    this.titleEditSetTicket = undefined;
    this.titleEditReservation = undefined;
    this.titleAddReservation = undefined;
    this.titleNewRegistrationSetTicket = undefined;
    this.titleDuplicateSetTicket = undefined;
  }

  /**
   * enlarge preview
   */
  changeSizePreview(): void {
    this.isEnlargePreview = !this.isEnlargePreview;
    this.sizePreview.emit(this.isEnlargePreview);
  }

  /**
   * Get title item
   * @param msg
   * @param param
   * @param param2
   */
  getStringTranslate(msg: string, param: string, param2?: string): string {
    const dataString = param2
      ? Helper.formatString(this.translateService.instant(`ticket-editor.combination.${msg}`), `${param}`, `${param2}`)
      : Helper.formatString(this.translateService.instant(`ticket-editor.combination.${msg}`), `${param}`);
    return dataString;
  }

  /**
   * Set height sale ticket detail
   */
  setHeightSaleTicketDetail(): string {
    const heightOrderSaleTicket = 418; // Height order set ticket
    const heightOrderSaleTicketEdit = 712; // Height order set ticket edit
    const heightRowTable = 26;
    const maxHeightRowTable = 156;
    const countOrderTicket = this.ticketSetDetail['combinationTicket'] ? this.ticketSetDetail['combinationTicket'].length : 0;
    const maxHeight = this.isEditSaleTicketDetail ? heightOrderSaleTicketEdit - heightRowTable : heightOrderSaleTicket;

    let height = maxHeight - maxHeightRowTable;
    if (countOrderTicket < 5) {
      height = maxHeight - heightRowTable * (countOrderTicket + 1);
      if (this.isDragDrop) {
        height = height - 56;
      }
    }
    return `${height}px`;
  }

  /**
   * Set Date
   * @param event
   * @param itemName
   */
  setDate(event: any, itemName: string): void {
    if (event) {
      const date = new Date(event);
      this.formSaleTicket.get(itemName).patchValue(date);
      this.ticketSetDetail[`isClear${itemName}`] = false;
    } else {
      this.formSaleTicket.get(itemName).patchValue('');
    }
  }

  /**
   * initFormSaleTicket
   */
  initFormSaleTicket(): void {
    this.formSaleTicket = this.formBuilder.group({
      saleTicketId: [],
      updateScheduleAt: [],
      saleTicketNameEN: [, [this.noWhitespaceValidator, Validators.minLength(1), Validators.maxLength(100)]],
      saleTicketNameJP: [, [this.noWhitespaceValidator, Validators.minLength(1), Validators.maxLength(100)]],
      appId: [, Validators.required],
      appName: [],
      companyId: [],
      companyNameEN: [],
      companyNameJP: [],
      operatorId: [],
      operatorNameEN: [],
      operatorNameJP: [],
      subtitleEN: [, [Validators.minLength(1), Validators.maxLength(100)]],
      subtitleJP: [, [Validators.minLength(1), Validators.maxLength(100)]],
      noteEN: [, [Validators.minLength(1), Validators.maxLength(1000)]],
      noteJP: [, [Validators.minLength(1), Validators.maxLength(1000)]],
      saleStartAt: [, [Validators.required]],
      saleEndAt: [],
      isSale: [],
      currencyUnit: [],
      priceType: [],
      adultSelection: [],
      custom1Selection: [],
      custom2Selection: [],
      custom3Selection: [],
      refundFeeMethod: [, [Validators.required]],
      priceAdult: [],
      custom1NameEN: [],
      custom1NameJP: [],
      custom1NoteEN: [],
      custom1NoteJP: [],
      custom2NameEN: [],
      custom2NameJP: [],
      custom2NoteEN: [],
      custom2NoteJP: [],
      custom3NameEN: [],
      custom3NameJP: [],
      custom3NoteEN: [],
      custom3NoteJP: [],
      priceCustom1: [],
      priceCustom2: [],
      priceCustom3: [],
      refundFeeAdult: [],
      refundFeeCustom1: [],
      refundFeeCustom2: [],
      refundFeeCustom3: [],
      refundFeeTicket: [],
      minCountAdult: [],
      maxCountAdult: [],
      minCountCustom1: [],
      maxCountCustom1: [],
      minCountCustom2: [],
      maxCountCustom2: [],
      minCountCustom3: [],
      maxCountCustom3: [],
      availablePeriod: [, Validators.required],
      availablePeriodUnit: ['D'],
      availableDeadlineAt: [],
      refundPeriod: [, Validators.required],
      refundPeriodUnit: ['D'],
      refundDeadlineAt: []
    });
  }

  /**
   * noWhitespaceValidator
   * @param control
   * @returns
   */
  public noWhitespaceValidator(control: FormControl) {
    return (control.value || '').trim().length ? null : { whitespace: true };
  }

  /**
   * validateOptinalFieds
   * @param fileControlname
   * @param minLength
   * @param maxLength
   * @param minValue
   * @param maxValue
   */
  validateOptinalFieds(
    fileControlname: string,
    minLength: number,
    maxLength: number,
    minValue: number,
    maxValue: number,
    isFixing?: boolean
  ): void {
    let fileControl = this.formSaleTicket.get(fileControlname);
    if (!isFixing) {
      if (
        fileControl?.value == null ||
        fileControl?.value == undefined ||
        (fileControl?.value && typeof fileControl?.value === 'string' && fileControl?.value?.trim().length === 0)
      ) {
        fileControl.clearValidators();
        fileControl.updateValueAndValidity();
      } else {
        if (minLength && maxLength) {
          fileControl.setValidators([Validators.minLength(minLength), Validators.maxLength(maxLength)]);
          fileControl?.updateValueAndValidity();
        }
        if (minValue !== null && maxValue !== null) {
          fileControl.setValidators([Validators.min(minValue), Validators.max(maxValue)]);
          fileControl?.updateValueAndValidity();
        }
      }
    } else {
      const currentValidators = this.formSaleTicket.get(fileControlname).validator;
      if (minLength && maxLength) {
        fileControl.setValidators([currentValidators, Validators.minLength(minLength), Validators.maxLength(maxLength)]);
        fileControl?.updateValueAndValidity();
      }
      if (minValue !== null && maxValue !== null) {
        fileControl.setValidators([currentValidators, Validators.min(minValue), Validators.max(maxValue)]);
        fileControl?.updateValueAndValidity();
      }
    }

    fileControl.markAllAsTouched();
  }

  /**
   * markTheElement
   * @param formGroup
   */
  markTheElement(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach(filed => {
      const control = formGroup.get(filed);
      if (control instanceof FormGroup) {
        this.markTheElement(control);
      } else if (control) {
        control.markAsTouched({ onlySelf: true });
      }
    });
  }

  /**
   * get form Control
   */
  get f() {
    return this.formSaleTicket.controls;
  }

  /**
   * getSalesCondition
   * @param value
   * @returns
   */
  getSalesCondition(value: string): string {
    const salesCondition =
      value == 'red'
        ? this.translateService.instant('ticket-editor.combination.sale')
        : this.translateService.instant('ticket-editor.combination.pause');
    return salesCondition ? salesCondition : '';
  }

  /**
   * getTimeUnit
   * @param value
   * @returns
   */
  getTimeUnit(value: string): string {
    if (!value) {
      return;
    } else if (value == 'D') {
      return this.translateService.instant('ticket-editor.combination.day');
    } else if (value == 'M') {
      return this.translateService.instant('ticket-editor.combination.month');
    }
  }

  /**
   * getFixedPrice
   * @param value
   * @returns
   */
  getFixedPrice(value: string): string {
    const fixedPrice =
      value == 'Fixed'
        ? this.translateService.instant('ticket-editor.combination.fixed')
        : this.translateService.instant('ticket-editor.combination.fluctuating');
    return fixedPrice ? fixedPrice : '';
  }

  /**
   * validateRequiredTime
   * @param fileControlname
   * @param dependentFileHandleName
   */
  validateRequiredTime(fileControlname: string, dependentFileHandleName: string): void {
    let fileControl = this.formSaleTicket.get(fileControlname);
    let dependentFileControl = this.formSaleTicket.get(dependentFileHandleName);
    if (fileControl?.value || (fileControl?.value && typeof fileControl?.value === 'string' && fileControl?.value?.trim().length > 0)) {
      dependentFileControl.setValidators([Validators.required]);
      dependentFileControl?.updateValueAndValidity();
    } else {
      dependentFileControl.clearValidators();
      dependentFileControl.updateValueAndValidity();
    }
  }

  /**
   *
   * @param property
   */
  displayInformation(property: string): void {
    this.ticketSetDetail[`${property}`] = !this.ticketSetDetail[`${property}`];
  }
  /**
   * onDrop
   * @param event
   */
  onDrop(event: CdkDragDrop<any[]>): void {
    if (!this.isDragDrop) {
      return;
    }
    if (event.previousContainer !== event.container) {
      // Ensure we are dropping into the destination list (list-connect)
      this.draggedItem = _.cloneDeep(event.item.data);
      //this.draggedItem.ticketId = this.setTicketSelected.saleTicketId;
      if (!this.ticketSetDetail.combinationTicket && event.container.id === 'list-connect') {
        this.ticketSetDetail.combinationTicket = new Array<CombinationTicket>();
        transferArrayItem([this.draggedItem], this.ticketSetDetail.combinationTicket, 0, event.currentIndex);
        this.ticketSetDetail.combinationTicket = [...this.ticketSetDetail.combinationTicket];
        this.ticketSetDetail.combinationTicket[0].displayPriority = 1;
      } else if (event.container.id === 'list-connect') {
        transferArrayItem([this.draggedItem], this.ticketSetDetail.combinationTicket, 0, event.currentIndex);
        this.ticketSetDetail.combinationTicket = [...this.ticketSetDetail.combinationTicket];
        const index = this.ticketSetDetail.combinationTicket.findIndex(e => !e.displayPriority);
        if (index != -1) {
          this.ticketSetDetail.combinationTicket[index].displayPriority = this.ticketSetDetail.combinationTicket.length;
        }
      }
    }
  }

  /**
   * validateDrop
   * @param drag
   * @param drop
   * @returns
   */
  validateDrop = (drag: CdkDrag, drop: CdkDropList) => {
    return drop.id === 'list-connect';
  };

  /**
   * enable Drag Drop
   */
  enableDragDrop(): void {
    this.isDragDrop = true;
  }

  /**
   * reorderRows
   * @param event
   * @returns
   */
  reorderRows(event: CdkDragDrop<any[]>) {
    if (!this.isEditSaleTicketDetail) {
      return;
    }

    if (event.isPointerOverContainer) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
      const index = this.ticketSetDetail.combinationTicket.indexOf(event.item.data);
      if (index !== -1) {
        this.ticketSetDetail.combinationTicket.splice(index, 1);
        this.ticketSetDetail.combinationTicket = [...this.ticketSetDetail.combinationTicket];
      }
    }
  }

  /**
   * changeDetailUpdate
   * @param status
   */
  changeDetailUpdate(status: boolean): void {
    if (!this.setTicketSelected) {
      return;
    }
    this.setTicketSelected.isChooseDetailUpdate = status;
    this.setTicketSelected.isChooseDetailUpdate
      ? (this.ticketSetDetail = this.setTicketSelected.saleTicketDetailReserved)
      : (this.ticketSetDetail = this.setTicketSelected.saleTicketDetail);
    if (this.setTicketSelected.isChooseDetailUpdate) {
      this.formSaleTicket.get('updateScheduleAt').setValidators(Validators.required);
      this.formSaleTicket.get('updateScheduleAt').updateValueAndValidity();
      this.formSaleTicket.get('saleStartAt').clearValidators();
      this.formSaleTicket.get('saleStartAt').updateValueAndValidity();
      this.formSaleTicket.get('saleEndAt').clearValidators();
      this.formSaleTicket.get('saleEndAt').updateValueAndValidity();
    }
  }

  /**
   * convertDate
   * @param date
   * @returns
   */
  convertDate(date: string) {
    if (date) {
      const year = parseInt(date.substring(0, 4));
      const month = parseInt(date.substring(5, 7)) - 1;
      const day = parseInt(date.substring(8, 10));
      const newDate = moment({ year, month, day }).toString();
      return new Date(newDate);
    }
  }

  /**
   * addSaleTicketUpdate
   */
  async addSaleTicketUpdate(): Promise<void> {
    if (!this.ticketSetDetail) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('ticket-editor.choose-sale-ticket')
        }
      });
      return;
    }
    this.titleFormData = this.translateService.instant('menu-ticket-editor.edit.tab-ticket.add-schedule');
    this.titleAddReservation = this.titleFormData;
    this.changeDetailUpdate(true);
    const data = _.cloneDeep(this.setTicketSelected.saleTicketDetail);
    data.isDisplayInformationTicketName = true;
    data.isDisplayInformationCompanyName = true;
    data.isDisplayInformationSubtitle = true;
    data.isDisplayInformationNote = true;
    data.isDisplayInformationNote1 = true;
    data.isDisplayInformationNote2 = true;
    data.isDisplayInformationNote3 = true;
    data.isDisplayInformationSalesName1 = true;
    data.isDisplayInformationSalesName2 = true;
    data.isDisplayInformationSalesName3 = true;
    await [(this.ticketSetDetail = data)];
    this.formSaleTicket.get('companyNameEN').disable();
    this.formSaleTicket.get('companyNameJP').disable();
    this.formSaleTicket.get('appId').disable();
    this.fillValueToForm(this.ticketSetDetail);
    // Select row set ticket
    this.isEditSaleTicketDetail = true;
    // this.createFormValidate();
    this.dataService.sendData([Constant.IS_EDITING_COMBINATION, true]);
  }

  /**
   * editSaleTicketUpdate
   * @returns
   */
  editSaleTicketUpdate() {
    if (!this.ticketSetDetail) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('ticket-editor.choose-sale-ticket')
        }
      });
      return;
    }
    this.titleFormData = this.translateService.instant('menu-ticket-editor.edit.tab-ticket.edit-schedule');
    this.titleEditReservation = this.titleFormData;
    this.isEditType = true;
    this.changeDetailUpdate(true);
    this.isEditSaleTicketDetail = true;
    this.ticketSetDetail.isDisplayInformationTicketName = true;
    this.ticketSetDetail.isDisplayInformationCompanyName = true;
    this.ticketSetDetail.isDisplayInformationSubtitle = true;
    this.ticketSetDetail.isDisplayInformationNote = true;
    this.ticketSetDetail.isDisplayInformationNote1 = true;
    this.ticketSetDetail.isDisplayInformationNote2 = true;
    this.ticketSetDetail.isDisplayInformationNote3 = true;
    this.ticketSetDetail.isDisplayInformationSalesName1 = true;
    this.ticketSetDetail.isDisplayInformationSalesName2 = true;
    this.ticketSetDetail.isDisplayInformationSalesName3 = true;
    this.formSaleTicket.get('companyNameEN').disable();
    this.formSaleTicket.get('companyNameJP').disable();
    this.formSaleTicket.get('appId').disable();
    this.fillValueToForm(this.ticketSetDetail);
    this.dataService.sendData([Constant.IS_EDITING_COMBINATION, true]);
  }

  /**
   * deleteSaleTicketUpdate
   */
  deleteSaleTicketUpdate() {
    const listSaleTicketIdSelected = this.listSaleTicket.filter(setTicket => setTicket.isSelected).map(e => e.saleTicketId);
    if (listSaleTicketIdSelected.length < 1) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('ticket-editor.choose-sale-ticket')
        }
      });
      return;
    }

    this.dialogService.showDialog(
      DialogConfirmComponent,
      {
        data: {
          text: this.translateService.instant('ticket-editor.combination.confirm-delete-checked-set-ticket-update'),
          button1: this.translateService.instant('ticket-editor.yes'),
          button2: this.translateService.instant('ticket-editor.btn-no'),
          title: this.translateService.instant('ticket-editor.tilte-delete-update')
        }
      },
      result => {
        if (!result) {
          return;
        }
        let payloads = [];
        for (let saleTicketId of listSaleTicketIdSelected) {
          let payload = {
            saleTicketId: saleTicketId
          };
          payloads.push(payload);
        }
        this.ticketEditorService.deleteSaleTicketUpdates(this.informationAccount, payloads).subscribe(
          (data: any) => {
            const saleTicketId = data;
            this.isEditSaleTicket = false;
            this.getListSaleTicket();
            this.dataService.sendData([Constant.THERE_ARE_PLANS_FOR_UPDATES, false]);
          },
          (error: any) => {
            return;
          }
        );
      }
    );
  }

  /**
   * translateTicket
   */
  translateTicket(): void {
    this.dialogService.showDialog(DialogTranslateTicketComponent, {}, result => {
      this.languages = result;
    });
  }

  /**
   * changeTitleFormData
   */
  changeTitleFormData() {
    if (this.titleFormData) {
      switch (this.titleFormData) {
        case this.titleNewRegistrationSetTicket:
          this.titleFormData = this.translateService.instant('menu-ticket-editor.edit.new-registration-set-ticket');
          this.titleNewRegistrationSetTicket = this.titleFormData;
          break;
        case this.titleDuplicateSetTicket:
          this.titleFormData = this.translateService.instant('menu-ticket-editor.edit.duplicate-set-ticket');
          this.titleDuplicateSetTicket = this.titleFormData;
          break;
        case this.titleEditSetTicket:
          this.titleFormData = this.translateService.instant('menu-ticket-editor.edit.edit-set-ticket');
          this.titleEditSetTicket = this.titleFormData;
          break;
        case this.titleAddReservation:
          this.titleFormData = this.translateService.instant('menu-ticket-editor.edit.tab-ticket.add-schedule');
          this.titleAddReservation = this.titleFormData;
          break;
        case this.titleEditReservation:
          this.titleFormData = this.translateService.instant('menu-ticket-editor.edit.tab-ticket.edit-schedule');
          this.titleEditReservation = this.titleFormData;
          break;
      }
    }
  }

  /**
   * checkDisplayInput
   * @returns
   */
  checkDisplayInput(): boolean {
    if (!this.titleFormData) {
      return false;
    }
    if (
      this.titleFormData == this.titleEditSetTicket ||
      this.titleFormData == this.titleEditReservation ||
      this.titleFormData == this.titleAddReservation
    ) {
      return false;
    }
    if (this.titleFormData == this.titleNewRegistrationSetTicket || this.titleFormData == this.titleDuplicateSetTicket) {
      return true;
    }
  }

  /**
   * checkDisplayInputSaleTicketTypeId
   * @returns
   */
  checkDisplaySaleTicketTypeId(): boolean {
    if (
      (this.titleFormData &&
        (this.titleFormData == this.titleNewRegistrationSetTicket || this.titleFormData == this.titleDuplicateSetTicket)) ||
      !this.setTicketSelected
    ) {
      return false;
    }
    return true;
  }

  /**
   * checkInputSaleStartAt
   * @returns
   */
  checkInputSaleStartAt(): boolean {
    if (
      !this.titleFormData ||
      (this.titleFormData && this.titleFormData == this.titleEditSetTicket && this.setTicketSelected.status !== 0) ||
      (this.titleFormData && this.titleFormData == this.titleAddReservation) ||
      (this.titleFormData && this.titleFormData == this.titleEditReservation)
    ) {
      return false;
    }
    return true;
  }

  /**
   * checkInputSaleEndAt
   * @returns
   */
  checkInputSaleEndAt(): boolean {
    if (
      !this.titleFormData ||
      (this.titleFormData && this.titleFormData == this.titleAddReservation) ||
      (this.titleFormData && this.titleFormData == this.titleEditReservation)
    ) {
      return false;
    }
    return true;
  }

  /**
   * getTicketName
   * @param ticketName
   * @returns
   */
  getTicketName(ticket: any): string {
    if (this.languageKey == 'en') {
      return ticket.ticketName.en ? ticket.ticketName.en : ticket.ticketName.ja;
    } else {
      return ticket.ticketName.ja ? ticket.ticketName.ja : ticket.ticketName.en;
    }
  }

  /**
   * checkDisplayApplicationStartDate
   * @returns
   */
  checkDisplayApplicationStartDate(): boolean {
    if (!this.titleFormData) {
      return this.setTicketSelected ? this.setTicketSelected.isChooseDetailUpdate : false;
    } else {
      return [this.titleAddReservation, this.titleEditReservation].includes(this.titleFormData);
    }
  }

  /**
   * Get name app display
   * @param value nameApp
   * @returns
   */
  changeDisplay(value: String): String {
    let temp = _.cloneDeep(value).toString();
    if (temp?.split('W')?.length > 10 && temp.length > 28) {
      value = value.substring(0, 28) + '...';
    } else if (value.length > 50) {
      value = value.substring(0, 50) + '...';
    }
    return value;
  }

  /**
   * clearDatePicker
   * @param itemName
   */
  public clearDatePicker(itemName: string): void {
    this.formSaleTicket.value[itemName] = null;
    this.ticketSetDetail[`isClear${itemName}`] = true;
    this.changeDetectorRef.detectChanges();
  }

  /**
   * getStatus
   * @param status
   */
  public getStatus(status: number): String {
    const statusObj = [
      {
        id: 0,
        nameJp: '販売開始前',
        nameEn: 'Before sales start'
      },
      {
        id: 1,
        nameJp: '販売中',
        nameEn: 'On sale'
      },
      {
        id: 2,
        nameJp: '販売停止中',
        nameEn: 'Sales suspended'
      },
      {
        id: 3,
        nameJp: '販売終了',
        nameEn: 'Sale ends'
      }
    ];
    let statusName;
    statusObj.forEach(e => {
      if (e.id == status) {
        statusName = this.languageKey == 'en' ? e.nameEn : e.nameJp;
      }
    });
    return statusName;
  }

  /**
   * checkChangeCategory
   * @param input
   */
  public checkChangeCategory(input: string, e?): void {
    if (input == '0') {
      if (this.formSaleTicket.value?.adultSelection) {
        this.formSaleTicket.get('priceAdult').setValidators(Validators.required);
        this.formSaleTicket.get('priceAdult').updateValueAndValidity();
        this.formSaleTicket.get('minCountAdult').setValidators(Validators.required);
        this.formSaleTicket.get('minCountAdult').updateValueAndValidity();
        this.formSaleTicket.get('maxCountAdult').setValidators(Validators.required);
        this.formSaleTicket.get('maxCountAdult').updateValueAndValidity();
      } else {
        this.formSaleTicket.get('priceAdult').clearValidators();
        this.formSaleTicket.get('priceAdult').setValue(undefined);
        this.formSaleTicket.get('priceAdult').updateValueAndValidity();
        this.formSaleTicket.get('minCountAdult').clearValidators();
        this.formSaleTicket.get('minCountAdult').setValue(undefined);
        this.formSaleTicket.get('minCountAdult').updateValueAndValidity();
        this.formSaleTicket.get('maxCountAdult').clearValidators();
        this.formSaleTicket.get('maxCountAdult').setValue(undefined);
        this.formSaleTicket.get('maxCountAdult').updateValueAndValidity();
        this.formSaleTicket.get('refundFeeAdult').clearValidators();
        this.formSaleTicket.get('refundFeeAdult').setValue(undefined);
        this.formSaleTicket.get('refundFeeAdult').updateValueAndValidity();
      }
    } else {
      if (this.formSaleTicket.value?.[`custom${input}Selection`]) {
        this.formSaleTicket.get(`priceCustom${input}`).setValidators(Validators.required);
        this.formSaleTicket.get(`priceCustom${input}`).updateValueAndValidity();
        this.formSaleTicket.get(`custom${input}NameEN`).setValidators(this.noWhitespaceValidator);
        this.formSaleTicket.get(`custom${input}NameEN`).updateValueAndValidity();
        this.formSaleTicket.get(`custom${input}NameJP`).setValidators(this.noWhitespaceValidator);
        this.formSaleTicket.get(`custom${input}NameJP`).updateValueAndValidity();
        this.formSaleTicket.get(`custom${input}NoteEN`).setValidators(this.noWhitespaceValidator);
        this.formSaleTicket.get(`custom${input}NoteEN`).updateValueAndValidity();
        this.formSaleTicket.get(`custom${input}NoteJP`).setValidators(this.noWhitespaceValidator);
        this.formSaleTicket.get(`custom${input}NoteJP`).updateValueAndValidity();
        this.formSaleTicket.get(`minCountCustom${input}`).setValidators(Validators.required);
        this.formSaleTicket.get(`minCountCustom${input}`).updateValueAndValidity();
        this.formSaleTicket.get(`maxCountCustom${input}`).setValidators(Validators.required);
        this.formSaleTicket.get(`maxCountCustom${input}`).updateValueAndValidity();
      } else {
        this.formSaleTicket.get(`priceCustom${input}`).clearValidators();
        this.formSaleTicket.get(`priceCustom${input}`).setValue(undefined);
        this.formSaleTicket.get(`priceCustom${input}`).updateValueAndValidity();
        this.formSaleTicket.get(`custom${input}NameEN`).clearValidators();
        this.formSaleTicket.get(`custom${input}NameEN`).setValue(undefined);
        this.formSaleTicket.get(`custom${input}NameEN`).updateValueAndValidity();
        this.formSaleTicket.get(`custom${input}NameJP`).clearValidators();
        this.formSaleTicket.get(`custom${input}NameJP`).setValue(undefined);
        this.formSaleTicket.get(`custom${input}NameJP`).updateValueAndValidity();
        this.formSaleTicket.get(`custom${input}NoteEN`).clearValidators();
        this.formSaleTicket.get(`custom${input}NoteEN`).setValue(undefined);
        this.formSaleTicket.get(`custom${input}NoteEN`).updateValueAndValidity();
        this.formSaleTicket.get(`custom${input}NoteJP`).clearValidators();
        this.formSaleTicket.get(`custom${input}NoteJP`).setValue(undefined);
        this.formSaleTicket.get(`custom${input}NoteJP`).updateValueAndValidity();
        this.formSaleTicket.get(`minCountCustom${input}`).clearValidators();
        this.formSaleTicket.get(`minCountCustom${input}`).setValue(undefined);
        this.formSaleTicket.get(`minCountCustom${input}`).updateValueAndValidity();
        this.formSaleTicket.get(`maxCountCustom${input}`).clearValidators();
        this.formSaleTicket.get(`maxCountCustom${input}`).setValue(undefined);
        this.formSaleTicket.get(`maxCountCustom${input}`).updateValueAndValidity();
        this.formSaleTicket.get(`refundFeeCustom${input}`).clearValidators();
        this.formSaleTicket.get(`refundFeeCustom${input}`).setValue(undefined);
        this.formSaleTicket.get(`refundFeeCustom${input}`).updateValueAndValidity();
      }
    }
  }

  /**
   * checkChangeSalePrice
   * @param input
   */
  checkChangeSalePrice(input: string) {
    if (input == '0') {
      if (this.formSaleTicket.value?.priceAdult) {
        this.formSaleTicket.get('refundFeeAdult').setValidators(Validators.required);
        this.formSaleTicket.get('refundFeeAdult').updateValueAndValidity();
      } else {
        this.formSaleTicket.get('refundFeeAdult').clearValidators();
        this.formSaleTicket.get('refundFeeAdult').setValue(undefined);
        this.formSaleTicket.get('refundFeeAdult').updateValueAndValidity();
      }
    } else {
      if (this.formSaleTicket.value?.[`priceCustom${input}`]) {
        this.formSaleTicket.get(`refundFeeCustom${input}`).setValidators(Validators.required);
        this.formSaleTicket.get(`refundFeeCustom${input}`).updateValueAndValidity();
      } else {
        this.formSaleTicket.get(`refundFeeCustom${input}`).clearValidators();
        this.formSaleTicket.get(`refundFeeCustom${input}`).setValue(undefined);
        this.formSaleTicket.get(`refundFeeCustom${input}`).updateValueAndValidity();
      }
    }
  }

  /**
   * checkChangeRefundFeeMethod
   * @param refundFeeMethod
   */
  public checkChangeRefundFeeMethod(refundFeeMethod: number): void {
    const PRICE_CLASS_VALUE = 1;
    if (refundFeeMethod && refundFeeMethod == PRICE_CLASS_VALUE) {
      this.formSaleTicket.get('refundFeeTicket').clearValidators();
      this.formSaleTicket.get('refundFeeTicket').setValue(undefined);
      this.formSaleTicket.get('refundFeeTicket').updateValueAndValidity();
      this.checkChangeSalePrice('0');
      this.checkChangeSalePrice('1');
      this.checkChangeSalePrice('2');
      this.checkChangeSalePrice('3');
    } else {
      this.formSaleTicket.get('refundFeeTicket').setValidators(Validators.required);
      this.formSaleTicket.get('refundFeeTicket').updateValueAndValidity();
      this.formSaleTicket.get('refundFeeAdult').clearValidators();
      this.formSaleTicket.get('refundFeeAdult').setValue(undefined);
      this.formSaleTicket.get('refundFeeAdult').updateValueAndValidity();
      this.formSaleTicket.get('refundFeeCustom1').clearValidators();
      this.formSaleTicket.get('refundFeeCustom1').setValue(undefined);
      this.formSaleTicket.get('refundFeeCustom1').updateValueAndValidity();
      this.formSaleTicket.get('refundFeeCustom2').clearValidators();
      this.formSaleTicket.get('refundFeeCustom2').setValue(undefined);
      this.formSaleTicket.get('refundFeeCustom2').updateValueAndValidity();
      this.formSaleTicket.get('refundFeeCustom3').clearValidators();
      this.formSaleTicket.get('refundFeeCustom3').setValue(undefined);
      this.formSaleTicket.get('refundFeeCustom3').updateValueAndValidity();
    }
  }
  /**
   * displayScheduleAt
   * @param time
   * @returns
   */
  displayScheduleAt(time: string) {
    if (!time) {
      return '';
    }
    return time.substring(0, 19).replace('T', ' ');
  }

  /**
   * getTicketType
   * @param ticketType
   * @returns
   */
  getTicketType(ticketType: string): string {
    if (!ticketType) {
      return;
    }
    if (ticketType == 'coupon') {
      return '回数券';
    } else if (ticketType == 'ticket') {
      return '企画券';
    }
  }
}
